import { Col, Row } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import Instructors from "../dashboard/Instructors";
import SmallCalendar from "../dashboard/SmallCalendar";
import TodaysActivities from "../dashboard/TodaysActivities";
import UpcomingEvents from "../dashboard/UpcomingEvents";
import MainUpcomingEvents from "./MainUpcomingEvents";

const Calendar = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar calendarActive="active" calenIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Calendar" />

          <Row className="mt-[10px]">
            {/* left column items */}
            <Col className="mt-[11px]" md={9}>
              <div
                className="h-[613px] overflow-auto bg-defaultWhite rounded-[20px] p-[20px] shadow-sm"
                id="calendarScroll"
              >
                {/* <SchedulerComponent /> */}
              </div>

              {/* upcoming events */}
              <Typography.HeadingFour
                text="Upcoming Events"
                xStyle="my-[20px] text-defaultText"
              />

              <MainUpcomingEvents />
            </Col>

            {/* right column items */}
            <Col className="mt-[11px]" md={3}>
              {/* calendar section */}
              <SmallCalendar />

              <hr />

              {/* today's activities */}
              <div className="mt-[20px] h-[220px] mb-[10px]">
                <Typography.MediumText
                  text={`Today's Activities`}
                  xStyle="font-semibold mb-[8px]"
                />

                <TodaysActivities />
              </div>

              <hr />

              {/* upcoming events */}
              <div className="mt-[20px] h-[170px] mb-[10px]">
                <Typography.MediumText
                  text="Upcoming Events"
                  xStyle="font-semibold mb-[8px]"
                />

                <UpcomingEvents />
              </div>

              <hr />

              {/* instructors */}
              <div className="mt-[20px] h-[220px]">
                <Typography.MediumText
                  text="Instructors"
                  xStyle="font-semibold mb-[8px]"
                />

                <Instructors />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
