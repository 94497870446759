interface withoutIconProps {
  onClick?: () => void;
  btnName?: string;
}

const Filled = ({ btnName, onClick }: withoutIconProps) => {
  return (
    <button
      className="bg-defaultBlue text-[21px] text-defaultWhite hover:bg-defaultBlueHover px-[61px] py-[10px] w-auto h-[50px] rounded-[33px]"
      onClick={onClick}
    >
      {btnName}
    </button>
  );
};

const Outlined = ({ btnName, onClick }: withoutIconProps) => {
  return (
    <button
      className="outline outline-1 text-[21px] outline-defaultBlue text-defaultBlue hover:outline-2 hover:outline-defaultBlueHover hover:text-defaultBlueHover px-[61px] py-[10px] w-auto h-[50px] rounded-[33px]"
      onClick={onClick}
      id="outlineBtn"
    >
      {btnName}
    </button>
  );
};

export const LargeButtons = {
  Filled,
  Outlined,
};
