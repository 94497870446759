export const global_variables = {
  signin: "/robocentre/auth/signin",
  signup: "/robocentre/auth/signup",
  verify_account: "/robocentre/auth/verify_account",
  forgetpassword: "/robocentre/auth/forgetpassword",
  resetpassword: "/robocentre/auth/resetpassword",

  //ward
  get_all_wards: "/robocentre/auth/parent-onboarding",
  add_new_ward: "/robocentre/auth/parent-onboarding",
  update_ward_info: "/robocentre/auth/parent-onboarding",
  enroll_ward: "/robocentre/roboparent/onboarding/ward",

  //image
  file_upload: "/robocentre/file/upload",

  //school
  school_types: "/robocentre/educational-type",
  grade_levels: "/robocentre/age-level",
  all_schools: "/robocentre/roboschool/onboarding/school",

  //terms and policies
  terms_and_policies: "/robocentre/terms-condition-policy",
};
