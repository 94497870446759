import React from "react";
import { Form } from "react-bootstrap";

const ChangeLanguage = () => {
  return (
    <div>
      <Form.Select
        aria-label="Default select example"
        className="mt-[18px] h-[40px] outline outline-1 outline-defaultBlue_60 rounded-[20px]"
      >
        <option value="1">English</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </Form.Select>
    </div>
  );
};

export default ChangeLanguage;
