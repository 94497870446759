import { Image, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import badgeImg from "../../images/badgeImg.png";
import NoBadges from "./NoBadges";
import { Link } from "react-router-dom";

const AllBadges = () => {
  // const [badges, setBadges] = useState([]);
  const badges = [];

  return (
    <Row>
      {badges.length !== 0 ? (
        <NoBadges />
      ) : (
        <>
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/badgedetails"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/badgedetails"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/badgedetails"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/badgedetails"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/badgedetails"
          />
          <SingleBadge
            badgeTitle="Badge Name"
            dateIssued="Jul. 32, 2023"
            to="/badgedetails"
          />
        </>
      )}
    </Row>
  );
};

interface badgeProps {
  onClick?: () => void;
  badgeTitle: string;
  dateIssued: string;
  to: string;
}

//data mapper
const SingleBadge = ({ to, onClick, badgeTitle, dateIssued }: badgeProps) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className="bstep1 h-[160px] md:w-[230px] rounded-[20px] bg-defaultWhite shadow-sm ml-[17px] mb-[24px] px-[36px] py-[15px] col-md-4"
    >
      <div className="flex flex-col justify-center items-center">
        <Image src={badgeImg} alt="..." fluid />
        <Typography.MediumText
          text={badgeTitle}
          xStyle="font-semibold text-defaultText mt-[10px]"
        />
        <Typography.SmallText text={`Issued: ${dateIssued}`} />
      </div>
    </Link>
  );
};

export default AllBadges;
