import { Typography } from "../../components/typography/Typography";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";

const UnpaidInvoiceAlert = () => {
  return (
    <>
      <div className="pstep5 mt-[30px] bg-defaultWhite h-[170px] shadow-sm rounded-[5px] py-[13px]">
        <div className="flex gap-[13px] justify-center items-center">
          <Typography.HeadingThree
            text="2"
            xStyle="text-gradient-lightPinkStart"
          />
          <Typography.MediumText
            text="Unpaid Invoices"
            xStyle="font-semibold text-defaultText"
          />
        </div>

        <div className="my-[10px] border border-t-defaultBlue_40"></div>

        <p className="text-[16px] mx-[20px] mb-[10px]">
          You have 2 invoices currently unpaid with a total balance of
          <span className="font-semibold"> GHS 763.00</span>
        </p>
      </div>

      <button
        className="pstep6 mt-[30px] bg-defaultWhite h-[40px] w-[201px] rounded-[5px] text-defaultBlue flex gap-[5px] justify-center items-center px-3 shadow-sm"
        onClick={() => alert("clicked")}
      >
        <Cog8ToothIcon className="w-5 h-5" />
        Payment Settings
      </button>
    </>
  );
};

export default UnpaidInvoiceAlert;
