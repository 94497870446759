import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const PaginationComponent = () => {
  return (
    <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to="#"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </Link>
        <Link
          to="#"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </Link>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">1</span> to{" "}
            <span className="font-medium">10</span> of{" "}
            <span className="font-medium">97</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px"
            aria-label="Pagination"
          >
            <Link
              to="#"
              className="relative inline-flex items-center px-2 py-2 text-defaultBlue_60 bg-defaultBlue_20 rounded-[10px] mr-[22px] hover:text-defaultWhite hover:bg-defaultBlue"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
            <Link
              to="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-defaultText"
            >
              1
            </Link>
            <Link
              to="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-defaultText"
            >
              2
            </Link>
            <Link
              to="#"
              className="relative hidden items-center px-4 py-2 text-sm font-semibold text-defaultText md:inline-flex"
            >
              3
            </Link>
            <Link
              to="#"
              className="relative hidden items-center px-4 py-2 text-sm font-semibold text-defaultText md:inline-flex"
            >
              4
            </Link>

            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-defaultText">
              ...
            </span>

            <Link
              to="#"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-defaultText"
            >
              30
            </Link>
            <Link
              to="#"
              className="relative inline-flex items-center px-2 py-2 text-defaultBlue_60 bg-defaultBlue_20 rounded-[10px] ml-[22px] hover:text-defaultWhite hover:bg-defaultBlue"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;
