import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { useGeneralRequests } from '../../context/generalContext';
import { Input, message, Modal, Spin } from 'antd';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
}



const PasswordChangeModal = ({ isOpened, handleClose }: modalProps) => {

    const { getMyWards, currWard } = useGeneralRequests()
    const [isBusy, setIsBusy] = useState(false)
    const [theUserName, setTheUserName] = useState("")
    const [thePassword, setThePassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")


    //onload
    useEffect(() => {
        if (currWard) {
            setTheUserName(currWard?.username)
        }
    }, [currWard])


    //handleUpdateInfo
    // handleUpdateInfo
    const handleUpdateInfo = async () => {
        if (!theUserName) {
            message.warning("Enter a username for your ward")
        } else if (!thePassword) {
            message.warning("Enter a password for your ward")
        } else if (thePassword !== confirmPassword) {
            message.warning("Passwords do not match")
        } else {
            let update_data = {
                "_id": currWard?._id,
                "firstName": currWard?.firstName,
                "lastName": currWard?.lastName,
                "dob": currWard?.dob,
                "gender": currWard?.gender,
                "username": theUserName,
                "password": thePassword,
                "status": "DRAFTED"
            }

            console.log("---toUpdate:", update_data)

            setIsBusy(true)

            BaseService.put_api(global_variables.update_ward_info, update_data)
                .then((response) => {
                    console.log("updateRes:", response?.data)
                    message.success(`Account information updated for ${currWard?.firstName} ${currWard?.lastName}`)
                    getMyWards()
                    setIsBusy(false)
                    handleClose()
                }).catch((error: any) => {
                    console.log("updateErr:", error)
                    message.error(error?.response?.data?.error || "An error occured. Please try again")
                    setIsBusy(false)
                })
        }
    }



    return (
        <>
            <Modal open={isOpened} onCancel={handleClose} centered keyboard={false} maskClosable={false} footer={null}>
                <p className='text-[18px] font-[500]'>Password Information for {currWard ? currWard?.firstName : 'Ward'}</p>
                <hr className='my-3' />

                {/* username & passwords */}
                <div className="mt-[20px]">
                    <Input className="h-[44px] rounded-[5px] w-full" value={theUserName} onChange={(e) => setTheUserName(e.target.value)} placeholder="Username" disabled={isBusy} />
                </div>
                <div className="mt-[20px]">
                    <Input className="h-[44px] rounded-[5px] w-full" value={thePassword} onChange={(e) => setThePassword(e.target.value)} placeholder="Password" disabled={isBusy} />
                </div>
                <div className="mt-[20px]">
                    <Input className="h-[44px] rounded-[5px] w-full" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" disabled={isBusy} />
                </div>

                {/* next button */}
                <button className="bg-defaultBlue text-white font-semibold h-[50px] rounded-[30px] w-full mt-[40px] disabled:cursor-not-allowed" onClick={handleUpdateInfo} disabled={isBusy}>
                    {
                        isBusy ?
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                            :
                            "Save"}
                </button>
            </Modal>
        </>
    )
}

export default PasswordChangeModal
