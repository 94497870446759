import React from "react";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import bigBadgeImg from "../../images/bigBadgeImg.png";
import qrcodeImg from "../../images/qrcodeImg.png";
import fbImg from "../../images/fbImg.png";
import igImg from "../../images/igImg.png";
import tweetImg from "../../images/tweetImg.png";

const BadgeDetails = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar badgesActive="active" badgesIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="flex gap-[18px] items-center">
            <Link to="/badges" className="text-defaultBlue text-[18px]">
              &#8592; Back
            </Link>
            <Typography.HeadingThree text="Achievement Details" />
          </div>

          <div className="mt-[10px] w-full md:h-[597px] rounded-[20px] bg-defaultWhite shadow-sm p-[33px] flex gap-[42px]">
            <div className="relative">
              <Image className="" src={bigBadgeImg} alt="..." fluid />

              <Typography.ExtraSmallText text="Awarded by" xStyle="mt-[30px]" />
              <Typography.MediumText
                text="MingoBlox LLC"
                xStyle="font-semibold"
              />

              <Typography.ExtraSmallText
                text="Date awarded"
                xStyle="mt-[10px]"
              />
              <Typography.MediumText
                text="June 23 2023"
                xStyle="font-semibold"
              />

              <Typography.ExtraSmallText text="Result" xStyle="mt-[10px]" />
              <Typography.MediumText
                text="9.4 of 10 points"
                xStyle="font-semibold"
              />

              <Image
                className="absolute bottom-0"
                src={qrcodeImg}
                alt="..."
                fluid
              />
            </div>

            <div className="w-full relative">
              <Typography.HeadingThree text="Collaboration" />
              <Typography.HeadingFour
                text="Awarded to: Mawuli Togbor"
                xStyle="my-[10px]"
              />

              <hr className="mb-[10px]" />

              <Typography.MediumText
                text="Description"
                xStyle="font-semibold"
              />

              <p className="text-md">
                The progress report is prepared for you to be able to follow
                your child’s development of conceptual understanding, skills,
                knowledge and learner proﬁle in a more detailed way. You will
                ﬁnd your child’s progress in Primary Years Programme (PYP)
                Approaches To Learning (ATL) skills, attitudes and behaviour.
                The teachers provide a stimulating, challenging and engaging
                learning environment, creating appropriate activities for the
                needs of all students’ learning styles."
              </p>

              <div className="md:absolute md:bottom-0 md:w-full">
                <hr className="my-[10px]" />

                <div className="flex justify-between items-center">
                  <Typography.MediumText
                    text="Badge ID. 8fU46y9069"
                    xStyle="mt-[10px] hidden md:block"
                  />
                  <div className="flex gap-[20px] justify-center">
                    <Typography.MediumText
                      text="Share badge on"
                      xStyle="mt-[10px] font-semibold hidden md:block"
                    />

                    <Link to="#">
                      <Image src={fbImg} alt="..." fluid />
                    </Link>
                    <Link to="#">
                      <Image src={igImg} alt="..." fluid />
                    </Link>
                    <Link to="#">
                      <Image src={tweetImg} alt="..." fluid />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeDetails;
