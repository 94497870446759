export const dummyPayment = [
  {
    id: 1,
    invoice: "IN576643",
    item: "Lorem 1",
    date: "2023-05-24T12:37:53",
    date_due: "2023-05-24T12:37:53",
    total: "3,500",
    paid: true,
  },
  {
    id: 2,
    invoice: "IN576423",
    item: "Lorem 2",
    date: "2023-05-24T12:37:53",
    date_due: "2023-05-24T12:37:53",
    total: "1,500",
    paid: false,
  },
  {
    id: 3,
    invoice: "IN576423",
    item: "Lorem 2",
    date: "2023-05-24T12:37:53",
    date_due: "2023-05-24T12:37:53",
    total: "1,500",
    paid: false,
  },
];
