import React from "react";
import { Form, Image } from "react-bootstrap";
import { OtherButtons } from "../../components/buttons/OtherButtons";
import { AllCards } from "../../components/cards/AllCards";
import { StackedChart } from "../../components/charts/StackedChart";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import Instructors from "./Instructors";
import LatestResultsCard from "./LatestResultsCard";
import PerformanceCard from "./PerformanceCard";
import SmallCalendar from "./SmallCalendar";
import TodaysActivities from "./TodaysActivities";
import UpcomingEvents from "./UpcomingEvents";
import NoActivities from "./NoActivities";


const Dashboard = () => {
  // const [activities, setActivities] = useState([]);
  const activities = [];


  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Dashboard" />

          <div className="mt-[10px] flex w-full gap-[30px]">
            {/* left section */}
            <div className="mt-[11px] w-[75%]">
              <div className="flex gap-[20px] w-full">
                <div className="step6 w-[70%]">
                  <AllCards.WelcomeCard />
                </div>

                <div className="mt-[20px] md:mt-[0px] w-[30%] step7">
                  <PerformanceCard />
                </div>
              </div>

              {activities.length === 0 ? (
                <div>
                  {/* charts */}
                  <div className="flex gap-[20px] w-full">
                    <div className="mt-[33px] step8 w-[40%]">
                      <LatestResultsCard />
                    </div>

                    <div className="mt-[33px] step9 w-[60%]">
                      <div className="border-0 shadow-sm rounded-[20px] p-[22px] h-[320px] bg-defaultWhite">
                        <div className="flex justify-between">
                          <Typography.MediumText
                            text="Time Spent on Learning"
                            xStyle="font-semibold"
                          />
                          <Form.Select
                            aria-label="Default select example"
                            className="w-[120px] text-[16px] text-defaultBlue border-none shadow-none cursor-pointer font-semibold"
                          >
                            <option value="first">This Week</option>
                            <option value="second">This Month</option>
                            <option value="third">This Year</option>
                          </Form.Select>
                        </div>

                        <StackedChart />
                      </div>
                    </div>
                  </div>

                  {/* current courses */}
                  <div className="mt-[20px] step10">
                    <div className="flex justify-between items-center py-[5px]">
                      <Typography.HeadingFour text="Current Courses" />
                      <OtherButtons.SeeAll
                        btnName="See all"
                        onClick={() => alert("Ooouuu")}
                      />
                    </div>

                    <div className="mt-[10px] lg:grid lg:grid-cols-2 gap-[20px]">
                      {
                        ["", ""].map((item, i) => (
                          <AllCards.CurrentCourses key={i}
                            image={
                              <Image
                                src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                                className="rounded-[12px] w-[96px] h-[84px] object-cover"
                                fluid
                              />
                            }
                            courseTitle="Robotics"
                            details1="3 New Assignment"
                            details2="1 video to watch"
                            percent={64}
                          />
                        ))
                      }
                    </div>
                  </div>

                  {/* suggested courses */}
                  <div className="mt-[30px] step11">
                    <div className="flex justify-between items-center py-[5px]">
                      <Typography.HeadingFour text="Suggested Courses" />
                      <OtherButtons.SeeAll
                        btnName="See all"
                        onClick={() => alert("Ooouuu")}
                      />
                    </div>

                    <div className="mt-[10px] lg:grid lg:grid-cols-2 gap-[20px]">
                      {
                        ["", "", "", ""].map((item, i) => (
                          <AllCards.SuggestedCourses key={i}
                            image={
                              <Image
                                src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                                className="rounded-[12px] w-[96px] h-[84px] object-cover"
                                fluid
                              />
                            }
                            courseTitle="Practical Electronics"
                            details1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                        molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                        numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                        optio, eaque rerum! Provident similique accusantium nemo autem."
                          />
                        ))
                      }

                    </div>
                  </div>
                </div>
              ) : (
                <NoActivities />
              )}
            </div>

            {/* right section */}
            <div className="mt-[11px] w-[25%]">
              {/* calendar section */}
              <div className="step12">
                <SmallCalendar />
              </div>

              <hr />

              {/* today's activities */}
              <div className="mt-[20px] h-[220px] mb-[10px] step13">
                <Typography.MediumText
                  text={`Today's Activities`}
                  xStyle="font-semibold mb-[8px]"
                />

                <TodaysActivities />
              </div>

              <hr />

              {/* upcoming events */}
              <div className="mt-[20px] h-[170px] mb-[10px] step14">
                <Typography.MediumText
                  text="Upcoming Events"
                  xStyle="font-semibold mb-[8px]"
                />

                <UpcomingEvents />
              </div>

              <hr />

              {/* instructors */}
              <div className="mt-[20px] h-[220px] step15">
                <Typography.MediumText
                  text="Instructors"
                  xStyle="font-semibold mb-[8px]"
                />

                <Instructors />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
