import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OtherButtons } from "../buttons/OtherButtons";
import { Typography } from "../typography/Typography";
import { useGeneralRequests } from "../../context/generalContext";
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, message } from 'antd'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdOutlineAccountCircle, MdOutlinePassword } from "react-icons/md";
import { IoTrashOutline, IoSchoolOutline } from "react-icons/io5";
import WardAccountInfo from "../updateLearnerInfo/wardAccountInfo";
import PasswordChangeModal from "../updateLearnerInfo/passwordChangeModal";
import { PlusIcon } from "@heroicons/react/24/outline";
import WardSchoolInfo from "../updateLearnerInfo/wardSchoolInfo";



const LearnerCard = () => {

  const navigate = useNavigate()
  const { allMyWards, currWard, setCurrWard } = useGeneralRequests()
  const [openAccountModal, setOpenAccountModal] = useState(false)
  const [openChangePass, setOpenChangePass] = useState(false)
  const [openSchoolModal, setOpenSchoolModal] = useState(false)

  // dropdown options
  const items: MenuProps['items'] = [
    {
      label: <button className="flex gap-2 items-center w-full p-2" onClick={() => setOpenAccountModal(true)}>
        <MdOutlineAccountCircle className="h-5 w-5" />
        <p className="font-[500]">Update Ward Info</p>
      </button>,
      key: '0',
    },
    {
      label: <button className="flex gap-2 items-center w-full p-2" onClick={() => setOpenChangePass(true)}>
        <MdOutlinePassword className="h-5 w-5" />
        <p className="font-[500]">Change Password</p>
      </button>,
      key: '1',
    },
    {
      label: <button className="flex gap-2 items-center w-full p-2" onClick={() => setOpenSchoolModal(true)}>
        <IoSchoolOutline className="h-5 w-5" />
        <p className="font-[500]">Update School</p>
      </button>,
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: <button className="flex gap-2 items-center w-full text-red-500 p-2">
        <IoTrashOutline className="h-5 w-5" />
        <p className="font-[500]">Remove Ward</p>
      </button>,
      key: '3',
    },
  ];

  return (
    <>
      {allMyWards.length === 0 ? (
        <div className="w-[177px] h-[159px] bg-defaultBlue bg-opacity-[10%] rounded-[17px] py-[15px] px-[20px]">
          <div className="flex justify-center w-full">
            <Avatar
              src="https://t3.ftcdn.net/jpg/01/28/56/34/360_F_128563441_kn96kL8fUOtfZlBRBV4kATepeGXuiLzI.jpg"
              className="h-[60px] w-[60px] object-cover"
            />
          </div>

          <div className="mt-[20px] h-[30px] bg-white rounded-[30px] flex items-center justify-between px-3 cursor-pointer" onClick={() => navigate("/add_learner")}>
            <p className="text-sm font-[500]">Add Learner</p>
            <div className="h-5 w-5 rounded-full bg-defaultBlue text-white flex justify-center items-center">
              <PlusIcon className="h-4 w-4" />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[177px] h-fit bg-defaultBlue bg-opacity-[10%] rounded-[17px] py-[15px] step1">
          {
            allMyWards?.length !== 0 &&
            <div className="flex justify-end pr-1">
              <Dropdown menu={{ items }} trigger={['click']}>
                <button>
                  <HiOutlineDotsVertical className="h-5 w-5 text-defaultBlue" />
                </button>
              </Dropdown>
            </div>
          }

          {/* images */}
          <div className="flex flex-col justify-center mb-[10px]">
            <div className="flex mx-auto">
              <Avatar
                className="h-[50px] w-[50px] object-cover"
                src={currWard?.image || "https://api.dicebear.com/7.x/miniavs/svg?seed=3"}
              />
            </div>

            <div className="text-center mt-1">
              <Typography.MediumText
                text={currWard?.firstName + " " + currWard?.lastName}
                xStyle="text-defaultBlue font-semibold mb-[10px]"
              />
            </div>

            <div className="px-[15px] flex relative">
              <Avatar.Group
                className="h-10 w-10 object-cover"
                maxCount={4}
              >
                {
                  allMyWards.filter((item: any) => item?.firstName !== currWard?.firstName).map((ward: any, i: number) => (
                    <Link key={i} to="#" className="w-full" onClick={() => {
                      setCurrWard(ward)
                      message.info(`Current selected ward is ${ward?.firstName} ${ward?.lastName}`)
                    }}>
                      <Avatar className="h-10 w-10 object-cover" src={ward?.image || "https://api.dicebear.com/7.x/miniavs/svg?seed=3"} />
                    </Link>
                  ))
                }
              </Avatar.Group>
              <div className="absolute right-6">
                <button className="h-10 w-10 bg-defaultBlue text-white rounded-full flex justify-center items-center" onClick={() => navigate("/add_learner")}>
                  <PlusIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>



          {
            allMyWards?.length === 0 && <button className="bg-defaultWhite rounded-[17px] h-[28px] w-[158px] flex gap-[10px] pl-[2px] items-center mr-auto ml-auto" onClick={() => navigate("/add_learner")}>
              <OtherButtons.PlusButton />

              <Typography.SmallText text="Add Learner" />
            </button>
          }
        </div>
      )}


      {/* account modal */}
      {
        openAccountModal && <WardAccountInfo isOpened={openAccountModal} handleClose={() => setOpenAccountModal(false)} />
      }

      {/* password modal */}
      {
        openChangePass && <PasswordChangeModal isOpened={openChangePass} handleClose={() => setOpenChangePass(false)} />
      }

      {/* school modal */}
      {
        openSchoolModal && <WardSchoolInfo isOpened={openSchoolModal} handleClose={() => setOpenSchoolModal(false)} />
      }
    </>
  );
};


export default LearnerCard;
