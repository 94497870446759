import React from "react";
import { Typography } from "../../components/typography/Typography";
import { Image } from "react-bootstrap";

const Children = () => {
  return (
    <div>
      <Typography.MediumText
        text="Children"
        xStyle="text-defaultText font-semibold"
      />

      <EachChild
        image="https://www.timeforkids.com/wp-content/uploads/2022/02/KOTY4.jpg"
        childName="Mawuli Togbor"
        childID="MBX95386p4509"
      />
      <EachChild
        image="https://www.incimages.com/uploaded_files/image/1920x1080/getty_168318604_69878.jpg"
        childName="Mawuli Togbor"
        childID="MBX95386p4509"
      />
    </div>
  );
};

interface childProps {
  image: any;
  childName: string;
  childID: string;
}

//child component
const EachChild = ({ image, childName, childID }: childProps) => {
  return (
    <div className="mt-[13px] flex gap-[18px] items-center">
      <Image
        className="w-[46px] h-[46px] object-cover rounded-full"
        src={image}
        alt="..."
        fluid
      />

      <div>
        <Typography.SmallText text={childName} xStyle="text-defaultText" />
        <Typography.SmallText text={childID} xStyle="text-defaultBlue" />
      </div>
    </div>
  );
};

export default Children;
