import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import { Link } from "react-router-dom";
import { Col, Image, Row } from "react-bootstrap";
import PrintAndExport from "./PrintAndExport";
import UnpaidInvoiceAlert from "./UnpaidInvoiceAlert";
import mingoLogo from "../../images/mingoLogo.png";

const InvoiceDetails = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar paymentsActive="active" paymentsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="flex gap-[18px] items-center">
            <Link to="/payments" className="text-defaultBlue text-[18px]">
              &#8592; Back
            </Link>
            <Typography.HeadingThree
              text="Invoice #mbx34795436"
              xStyle="text-defaultText"
            />
          </div>

          <Row className="mt-[10px]">
            <Col md={9}>
              <div className="outline outline-1 outline-defaultBlue_40 bg-defaultWhite rounded-[5px] px-[60px] py-[40px]">
                <div className="flex justify-between items-center">
                  <Image src={mingoLogo} alt="..." fluid />
                  <Typography.HeadingThree
                    text="Unpaid"
                    xStyle="text-defaultRed"
                  />
                </div>
                <div className="mt-[15px] flex justify-between items-center">
                  <Typography.HeadingThree
                    text="Invoice #mbx34795436"
                    xStyle="text-defaultText"
                  />

                  <button
                    className="bg-defaultGreen text-defaultWhite rounded-[5px] h-[40px] w-[109px]"
                    onClick={() => alert("Paid")}
                  >
                    Pay Now
                  </button>
                </div>

                <hr className="my-[20px]" />

                {/* first content */}
                <div className="flex justify-between items-center">
                  {/* invoiced to */}
                  <div>
                    <Typography.MediumText
                      text="Invoiced to"
                      xStyle="font-semibold"
                    />
                    <Typography.SmallText
                      text="Vivian Togbor"
                      xStyle="pt-1 font-medium"
                    />
                    <Typography.SmallText
                      text="Presidential Floor, Mövenpick Ambassador Hotel"
                      xStyle="font-medium"
                    />
                    <Typography.SmallText
                      text="Accra - Ghana"
                      xStyle="font-medium"
                    />
                  </div>

                  {/* pay to */}
                  <div className="text-right">
                    <Typography.MediumText
                      text="Pay to"
                      xStyle="font-semibold"
                    />
                    <Typography.SmallText
                      text="Mingo Blox LLC"
                      xStyle="pt-1 font-medium"
                    />
                    <Typography.SmallText
                      text="Presidential Floor, Mövenpick Ambassador Hotel"
                      xStyle="font-medium"
                    />
                    <Typography.SmallText
                      text="Accra - Ghana"
                      xStyle="font-medium"
                    />
                  </div>
                </div>

                <Typography.SmallText
                  text="VAT No. 3456857907"
                  xStyle="font-medium mt-[30px] flex justify-end"
                />

                {/* second content */}
                <div className="flex justify-between items-center mt-[30px]">
                  {/* invoice date */}
                  <div>
                    <Typography.MediumText
                      text="Invoice date"
                      xStyle="font-semibold"
                    />
                    <Typography.SmallText
                      text="23/02/4545"
                      xStyle="pt-1 font-medium"
                    />
                  </div>

                  {/* payment method */}
                  <div className="text-right">
                    <Typography.MediumText
                      text="Payment Method"
                      xStyle="font-semibold"
                    />
                    <Typography.SmallText
                      text="Credit Card"
                      xStyle="pt-1 font-medium"
                    />
                  </div>
                </div>

                <hr className="my-[20px]" />

                <Typography.HeadingThree
                  text="Invoice Item"
                  xStyle="text-defaultText"
                />

                {/* money section */}
                <div className="mt-[10px] flex justify-between items-center">
                  <Typography.MediumText
                    text="Description"
                    xStyle="text-defaultText font-semibold"
                  />
                  <Typography.MediumText
                    text="Amount"
                    xStyle="text-defaultText font-semibold"
                  />
                </div>

                {/* list of items */}
                <div className="">
                  <div className="flex justify-between items-center bg-defaultBlue_5 mt-[5px] p-[10px]">
                    <Typography.MediumText
                      text="Lorem ipsum dolor sit amet, consetetur sadipscing"
                      xStyle="text-defaultText"
                    />
                    <Typography.MediumText
                      text="$46.67"
                      xStyle="text-defaultText"
                    />
                  </div>
                  <div className="flex justify-between items-center bg-defaultBlue_5 mt-[5px] p-[10px]">
                    <Typography.MediumText
                      text="Lorem ipsum dolor sit amet, consetetur sadipscing"
                      xStyle="text-defaultText"
                    />
                    <Typography.MediumText
                      text="$46.67"
                      xStyle="text-defaultText"
                    />
                  </div>
                  <div className="flex justify-between items-center bg-defaultBlue_5 mt-[5px] p-[10px]">
                    <Typography.MediumText
                      text="Lorem ipsum dolor sit amet, consetetur sadipscing"
                      xStyle="text-defaultText"
                    />
                    <Typography.MediumText
                      text="$46.67"
                      xStyle="text-defaultText"
                    />
                  </div>

                  {/* total section */}
                  <div className="flex justify-end items-center bg-defaultBlue_5 mt-[5px] p-[10px]">
                    <div className="flex gap-[50px]">
                      <Typography.MediumText
                        text="Subtotal"
                        xStyle="text-defaultText font-semibold"
                      />
                      <Typography.MediumText
                        text="$46.67"
                        xStyle="text-defaultText"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end items-center bg-defaultBlue_5 mt-[5px] p-[10px]">
                    <div className="flex gap-[50px]">
                      <Typography.MediumText
                        text="Credit"
                        xStyle="text-defaultText font-semibold"
                      />
                      <Typography.MediumText
                        text="$46.67"
                        xStyle="text-defaultText"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end items-center bg-defaultBlue_5 mt-[5px] p-[10px]">
                    <div className="flex gap-[50px]">
                      <Typography.MediumText
                        text="Total"
                        xStyle="text-defaultText font-semibold"
                      />
                      <Typography.MediumText
                        text="$346,564.00"
                        xStyle="text-defaultBlue font-semibold"
                      />
                    </div>
                  </div>
                </div>

                {/* the last section */}
                <div className="my-[30px]">
                  <hr />

                  <div className="mt-[10px] flex gap-[80px]">
                    <div>
                      <Typography.SmallText
                        text="+233 (0)24 444 6002"
                        xStyle="text-defaultText font-medium opacity-70"
                      />
                      <Typography.SmallText
                        text="www.mingoblox.com"
                        xStyle="text-defaultText font-medium opacity-70"
                      />
                    </div>
                    <div>
                      <Typography.SmallText
                        text="info@mingoblox.com"
                        xStyle="text-defaultText font-medium opacity-70"
                      />
                      <Typography.SmallText
                        text="Presidential Floor, Movenpick Ambassador Hotel, Accra"
                        xStyle="text-defaultText font-medium opacity-70"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              {/* print and export */}
              <PrintAndExport />

              {/* invoice section */}
              <UnpaidInvoiceAlert />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
