import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';
import { getCookie } from '../helpers/utils';


// Create the context
const GeneralContext = createContext<any>(null);

// Create a custom hook for easy access
export const useGeneralRequests = () => useContext(GeneralContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const GeneralContextProvider: React.FC<ApiProviderProps> = ({ children }) => {
    const [allMyWards, setAllMyWards] = useState([])
    const [userData, setUserData] = useState<any>(null)
    const [currWard, setCurrWard] = useState<any>(null)

    const getMyWards = async () => {
        try {
            const response = await BaseService.get_api(`${global_variables.get_all_wards}`)
            console.log("---allwardsRes:", response?.data)
            setAllMyWards(response?.data?.payload)
            setCurrWard(response?.data?.payload?.[0])
        } catch (error) {
            console.log("allWardsErr:", error)
        }
    };

    //onload
    useEffect(() => {
        getMyWards()
        const user_data = JSON.parse(getCookie("user_data"))
        setUserData(user_data)
    }, [])

    return (
        <GeneralContext.Provider value={{ allMyWards, getMyWards, userData, currWard, setCurrWard }}>
            {children}
        </GeneralContext.Provider>
    );
};
