import { OtherButtons } from "../../components/buttons/OtherButtons";
import { Typography } from "../../components/typography/Typography";

const LatestResultsCard = () => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] p-[22px] h-[320px] bg-defaultWhite">
      <div className="flex justify-between">
        <Typography.MediumText
          text="Latest Results"
          xStyle="font-semibold w-[148px]"
        />
        <OtherButtons.SeeAll
          btnName="See all"
          onClick={() => alert("Ooouuu")}
        />
      </div>

      <div className="mt-[9px]">
        <DataLookLike percentage="25%" color="text-tertiary-pink" />
        <DataLookLike percentage="55%" color="text-defaultBlue" />
        <DataLookLike percentage="90%" color="text-secondary-green" />
        <DataLookLike percentage="30%" color="text-tertiary-pink" />
      </div>
    </div>
  );
};

// data look like
const DataLookLike = ({ percentage, color }: any) => {
  return (
    <div className="flex items-center justify-between py-[14px] border-b border-defaultBlue_40">
      <div className="flex items-center gap-[5px] w-full">
        {/* <Typography.MediumText text="Topic 1" xStyle="font-semibold" />
        <span className="text-defaultBlue_60">-</span> */}
        <p className="text-[16px] text-defaultBlue_60 ml-[2px] md:w-[70px] truncate">
          Robotics Adventure
        </p>
        <div className="md:w-[100px] w-[130px]">
          <div className="bg-gray-200 h-[4px] rounded-[5px]">
            <div
              className="bg-defaultBlue h-[4px] rounded-[5px]"
              style={{ width: `${percentage}` }}
            ></div>
          </div>
        </div>
      </div>

      <Typography.MediumText
        text={percentage}
        xStyle={`font-semibold ${color}`}
      />
    </div>
  );
};

export default LatestResultsCard;
