import { Typography } from "../../components/typography/Typography";
import ChangeLanguage from "./ChangeLanguage";

const NameAndRole = () => {
  return (
    <div className="text-center mt-[10px]">
      <Typography.MediumText
        text="Vivan Togbor"
        xStyle="font-semibold text-defaultText"
      />
      <Typography.SmallText text="Guardian" xStyle="text-defaultBlue" />

      <div>
        {/* change password button */}
        <div className="sstep4">
          <button
            className="mt-[24px] border-[1px] border-defaultBlue text-defaultBlue bg-white py-2 w-full rounded-[30px]"
            onClick={() => alert("clicked")}
          >
            Change Password
          </button>
        </div>

        {/* language section */}
        <div className="sstep5">
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
};

export default NameAndRole;
