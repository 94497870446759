import { useState } from "react";
import { Typography } from "../../components/typography/Typography";
import { PencilIcon } from "@heroicons/react/24/outline";
import { SmallButtons } from "../../components/buttons/SmallButtons";
import { Col, Row } from "react-bootstrap";
import EditProfileModal from "./EditProfileModal";

const PersonalDetails = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="bg-defaultWhite md:h-[472px] w-full rounded-[20px] px-[30px] py-[25px] text-defaultText">
        <div className="flex justify-between items-center">
          <Typography.MediumText
            text="Personal Details"
            xStyle="font-semibold"
          />
          <div className="sstep2">
            <SmallButtons.OutlinedWithIcon
              btnName="Edit details"
              icon={<PencilIcon className="w-5 h-5" />}
              onClick={() => handleShow()}
            />
          </div>
        </div>

        <hr className="mt-2" />

        <Row>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="First Name" />
            <Typography.MediumText text="Vivian" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Prefered Name" />
            <Typography.MediumText text="Lorem Ipsum" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Contact 1" />
            <Typography.MediumText text="+233000221456" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Last Name" />
            <Typography.MediumText text="Togbor" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Date of Birth" />
            <Typography.MediumText text="28/05/2016" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Parent ID" />
            <Typography.MediumText text="RCS848480289" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Middle Name" />
            <Typography.MediumText text="Abena" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Email" />
            <Typography.MediumText
              text="lorem@dolor.com"
              xStyle="text-defaultBlue"
            />
          </Col>
        </Row>

        <hr className="my-[22px]" />

        <div>
          <Typography.MediumText
            text="Contact Details"
            xStyle="font-semibold"
          />

          <Row>
            <Col md={4} className="pt-[20px]">
              <Typography.SmallText text="Contact 1" />
              <Typography.MediumText text="+233000221456" />
            </Col>
            <Col md={4} className="pt-[20px]">
              <Typography.SmallText text="Country" />
              <Typography.MediumText text="Ghana" />
            </Col>
            <Col md={4} className="pt-[20px]">
              <Typography.SmallText text="State" />
              <Typography.MediumText text="Igbo State" />
            </Col>
            <Col md={4} className="pt-[20px]">
              <Typography.SmallText text="Contact 2" />
              <Typography.MediumText text="+233000221456" />
            </Col>
            <Col md={4} className="pt-[20px]">
              <Typography.SmallText text="City" />
              <Typography.MediumText text="Accra" />
            </Col>
            <Col md={4} className="pt-[20px]">
              <Typography.SmallText text="Address" />
              <Typography.MediumText text="Doe Street" />
            </Col>
          </Row>
        </div>
      </div>

      <EditProfileModal showModal={show} handleModalClose={handleClose} />
    </>
  );
};

export default PersonalDetails;
