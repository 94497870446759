import { Typography } from "../../components/typography/Typography";
import PaginationComponent from "./PaginationComponent";

const MainUpcomingEvents = () => {
  return (
    <div className="text-defaultText h-[613px] bg-defaultWhite rounded-[20px] p-[20px] shadow-sm relative">
      <SingleEvent />
      <SingleEvent />

      <div className="mt-[10px]">
        <PaginationComponent />
      </div>
    </div>
  );
};

//single event data mapper
const SingleEvent = () => {
  return (
    <div className="h-[68px] outline outline-1 outline-defaultBlue_40 py-[13px] px-[16px] rounded-[20px] my-[10px]">
      <div className="flex gap-[20px] items-center mb-[13px]">
        <div className="w-[38px] h-[40px] rounded-[10px] bg-defaultBlue_20 flex flex-col justify-center items-center">
          <div className="bg-defaultBlue text-defaultWhite w-full h-[16px] rounded-full flex justify-center items-center">
            <Typography.SmallText text="Feb" />
          </div>
          <Typography.MediumText text="24" xStyle="text-defaultBlue" />
        </div>

        <div>
          <Typography.MediumText text="Robocode Hour" />

          <div className="flex gap-[8px] item-center">
            <p className="bg-secondary-orange text-defaultWhite text-center font-medium text-[12px] rounded-[20px] w-[50px]">
              Late
            </p>

            <Typography.SmallText
              text="8:00 AM - 4:00 PM"
              xStyle="font-medium text-defaultGray"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainUpcomingEvents;
