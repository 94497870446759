import { ArrowUpTrayIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { Typography } from "../../components/typography/Typography";

const PrintAndExport = () => {
  return (
    <div className="pstep4">
      <button className="text-defaultBlue bg-defaultWhite rounded-[5px] outline outline-1 outline-defaultBlue w-[141px] h-[40px] flex justify-center items-center gap-[10px]">
        <PrinterIcon className="w-5 h-5" />
        <Typography.MediumText text="Print" />
      </button>
      <br />
      <button className="text-defaultBlue bg-defaultWhite rounded-[5px] outline outline-1 outline-defaultBlue w-[185px] h-[40px] flex justify-center items-center gap-[10px]">
        <ArrowUpTrayIcon className="w-5 h-5" />
        <Typography.MediumText text="Export CSV" />
      </button>
    </div>
  );
};

export default PrintAndExport;
