import SuggestedCourses from "./SuggestedCourses";
import { Typography } from "../../components/typography/Typography";
import nocoursesImg from "../../images/nocoursesImg.png";
import { Image } from "react-bootstrap";

const NoCourses = () => {
  return (
    <>
      <div className="mt=[27px] bg-defaultWhite h-[199px] w-full rounded-[20px] flex md:gap-[88px] gap-[40px] justify-center items-center">
        <Image
          className="w-[197px] h-[131px]"
          src={nocoursesImg}
          alt="..."
          fluid
        />

        <Typography.MediumText
          text="You will find your finished courses here."
          xStyle="w-[238px] text-defaultText"
        />
      </div>

      <SuggestedCourses />
    </>
  );
};

export default NoCourses;
