import { PlusIcon } from "@heroicons/react/24/outline";
import { Image } from "react-bootstrap";

const ProfilePicture = () => {
  return (
    <div className="sstep3">
      <div className="relative">
        <Image
          className="w-[133px] h-[133px] flex ml-auto mr-auto rounded-full object-cover"
          src="https://parade.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTkwNTc5NjU1ODUyNDM1MzI0/quotes-about-mothers-day-jpg.jpg"
          alt="..."
          fluid
        />

        <input
          className="mb-3"
          accept="image/*"
          type="file"
          id="profilepic"
          hidden
        />

        <label htmlFor="profilepic" className="absolute bottom-0 right-[70px]">
          <PlusIcon className="bg-defaultBlue w-[30px] h-[30px] rounded-full cursor-pointer p-1 text-defaultWhite" />
        </label>
      </div>
    </div>
  );
};

export default ProfilePicture;
