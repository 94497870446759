import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/css/App.css";
import "./css/GlobalStyles.css";
import GetStarted from "./pages/get_started/GetStarted";
import AddLearner from "./pages/get_started/add_learner/AddLearner";
import Settings from "./pages/settings_page/Settings";
import Reports from "./pages/reports_page/Reports";
import ReportDetails from "./pages/reports_page/ReportDetails";
import Payments from "./pages/payments/Payments";
import InvoiceDetails from "./pages/payments/InvoiceDetails";
import Dashboard from "./pages/dashboard/Dashboard";
import Courses from "./pages/courses/Courses";
import Calendar from "./pages/calendar/Calendar";
import Badges from "./pages/badges/Badges";
import BadgeDetails from "./pages/badges/BadgeDetails";
import ProtectedRoutes from "./router/router";
import { GeneralContextProvider } from "./context/generalContext";

function App() {
  return (
    <ProSidebarProvider>
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<ProtectedRoutes><GetStarted /></ProtectedRoutes>} />
            <Route path="/add_learner" element={<ProtectedRoutes><AddLearner /></ProtectedRoutes>} />
            <Route path="/dashboard" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/badges" element={<Badges />} />
            <Route path="/badgedetails" element={<BadgeDetails />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/reportdetails" element={<ReportDetails />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/invoicedetails" element={<InvoiceDetails />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Router>
      </GeneralContextProvider>
    </ProSidebarProvider>
  );
}

export default App;
