import { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import SmallCalendar from "../dashboard/SmallCalendar";
import TodaysActivities from "../dashboard/TodaysActivities";
import UpcomingEvents from "../dashboard/UpcomingEvents";
import { Col, Row } from "react-bootstrap";
import AllBadges from "./AllBadges";
import { badgesTour } from "../../utils/tourSteps";
import Joyride from "react-joyride";
import { isTourComplete } from "../../utils/isTourComplete";

const Badges = () => {
  //tour steps
  const [steps] = useState(badgesTour);

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {isTourComplete ? (
        <></>
      ) : (
        <Joyride
          steps={steps}
          run
          showSkipButton
          continuous
          showProgress
          styles={{
            options: {
              arrowColor: "rgba(0, 0, 0, 0.80)",
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              overlayColor: "rgba(0, 0, 0, 0.80)",
              primaryColor: "#6666EA",
              textColor: "#fff",
              zIndex: 1000,
            },
          }}
        />
      )}
      {/* Sidebar */}
      <ProSideBar badgesActive="active" badgesIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Badges" />

          <Row className="mt-[10px]">
            {/* left column items */}
            <Col md={9} className="mt-[10px">
              <AllBadges />
            </Col>

            {/* right column items */}
            <Col className="mt-[10px]" md={3}>
              {/* calendar section */}
              <SmallCalendar />

              <hr />

              {/* today's activities */}
              <div className="mt-[20px] h-[220px] mb-[10px]">
                <Typography.MediumText
                  text={`Today's Activities`}
                  xStyle="font-semibold mb-[8px]"
                />

                <TodaysActivities />
              </div>

              <hr />

              {/* upcoming events */}
              <div className="mt-[20px] h-[170px] mb-[10px]">
                <Typography.MediumText
                  text="Upcoming Events"
                  xStyle="font-semibold mb-[8px]"
                />

                <UpcomingEvents />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Badges;
