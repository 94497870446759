import DataTable from "react-data-table-component";
import { dummyPayment } from "./dummyData";
import { Dropdown, Form } from "react-bootstrap";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { StatusTags } from "../../components/statusTags/StatusTags";
import { useNavigate } from "react-router-dom";

const PaymentsTable = () => {
  const navigate = useNavigate();
  //search function
  const doSearch = () => {
    // e.preventDefault();
    alert("searching...");
  };

  //Table columns
  const columns = [
    {
      name: <p className="font-[700] text-[14px]">Invoice</p>,
      selector: (row: any) => row.invoice,
    },
    {
      name: <p className="font-[700] text-[14px]">Item</p>,
      selector: (row: any) => row.item,
    },
    {
      name: <p className="font-[700] text-[14px]">Date</p>,
      selector: (row: any) => moment(row.date).format("Do MMM YYYY"),
      width: "15%",
    },
    {
      name: <p className="font-[700] text-[14px]">Date Due</p>,
      selector: (row: any) => moment(row.date_due).format("Do MMM YYYY"),
      width: "15%",
    },
    {
      name: <p className="font-[700] text-[14px]">Total</p>,
      selector: (row: any) => row.total,
    },
    {
      name: <p className="font-[700] text-[14px]">Status</p>,
      selector: (row: any) =>
        row.paid ? (
          <StatusTags.Paid tagName="Paid" />
        ) : (
          <StatusTags.Unpaid tagName="Unpaid" />
        ),
    },
    {
      name: "",
      cell: (row: any, index: any) => (
        <>
          <Dropdown id="optionsToggle">
            <Dropdown.Toggle className="text-defaultBlue shadow-none border-none hover:bg-transparent hover:text-defaultBlue focus:bg-transparent focus:text-defaultBlue">
              <EllipsisVerticalIcon className="h-6 w-6" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-0 shadow-sm">
              <Dropdown.Item className="p-2" href="#">
                <p className="text-[14px]">Option 1</p>
              </Dropdown.Item>

              <hr />

              <Dropdown.Item className="p-2" href="#">
                <p className="text-[14px]">Option 2</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="bg-defaultWhite rounded-[20px] py-[30px] px-[20px] shadow-sm flex justify-between items-center">
        <div>
          <Form className="pstep2" onSubmit={() => doSearch()}>
            <Form.Group className="relative">
              <Form.Control
                className="h-[40px] md:w-[339px] w-[200px] rounded-[25px] shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 pr-8"
                placeholder="Search"
              />
              <MagnifyingGlassIcon
                className="w-5 h-5 text-defaultBlue_40 absolute right-3 bottom-2.5 hidden md:block"
                type="submit"
                onClick={() => doSearch()}
              />
            </Form.Group>
          </Form>
        </div>
        <div>
          <Form.Select
            aria-label="Default select example"
            className="pstep3 shadow-none border-defaultBlue_40 focus:border-defaultBlue_40"
          >
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
          </Form.Select>
        </div>
      </div>

      <div className="pstep1 bg-defaultWhite rounded-[20px] mt-2 px-[20px] py-[10px] shadow-sm">
        <DataTable
          responsive
          progressComponent="Loading..."
          // progressComponent={<TableShimmer />}
          // progressPending={loading}
          data={dummyPayment}
          columns={columns}
          highlightOnHover
          pointerOnHover
          onRowClicked={(row: any) =>
            navigate(`/invoicedetails?inv=${row.invoice}`)
          }
          pagination
          paginationServer
          // noDataComponent={
          //   <div>
          //     <NoDataComponent />

          //     <p>No data to display</p>
          //   </div>
          // }
          // paginationTotalRows={totalRows}
          // onChangeRowsPerPage={rowChange}
          // onChangePage={changePage}
        />
      </div>
      <br />
    </>
  );
};

export default PaymentsTable;
