import React from "react";
import NavSearchBar from "./NavSearchBar";
import ProfilePic from "./ProfilePic";
import SchoolName from "./SchoolName";
import { useGeneralRequests } from "../../context/generalContext";

const Navbar = () => {

  const { allMyWards } = useGeneralRequests()

  return (
    <nav className="px-[16px] py-[20px] z-10 w-full">
      <div className="flex justify-between">
        <div className="hidden lg:block">
          {
            allMyWards.length !== 0 && <SchoolName />
          }
        </div>

        <div className="flex gap-[20px] items-center">
          <div className="hidden md:block step3">
            <NavSearchBar />
          </div>

          <ProfilePic />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
