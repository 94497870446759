//dashboard
export const dashboardTour = [
  {
    target: ".step1",
    content: "This is a welcome card",
    disableBeacon: true,
  },
  {
    target: ".step2",
    content: "This is a performance card",
  },
  {
    target: ".step3",
    content: "This is something",
  },
  {
    target: ".step4",
    content: "This is something",
  },
  {
    target: ".step5",
    content: "This is something",
  },
  {
    target: ".step6",
    content: "This is something",
  },
  {
    target: ".step7",
    content: "This is something",
  },
  {
    target: ".step8",
    content: "This is something",
  },
  {
    target: ".step9",
    content: "This is something",
  },
  {
    target: ".step10",
    content: "This is something",
  },
  {
    target: ".step11",
    content: "This is something",
  },
  {
    target: ".step12",
    content: "This is something",
  },
  {
    target: ".step13",
    content: "This is something",
  },
  {
    target: ".step14",
    content: "This is something",
  },
  {
    target: ".step15",
    content: "This is something",
  },
];

//courses
export const coursesTour = [
  {
    target: ".cstep1",
    content: "All courses, in progress and completed",
  },
  {
    target: ".cstep2",
    content: "Overall progress",
  },
];

//badges
export const badgesTour = [
  {
    target: ".bstep1",
    content: "This is a badge",
  },
];

//reports
export const reportsTour = [
  {
    target: ".rstep1",
    content: "This is a badge",
  },
  {
    target: ".rstep2",
    content: "Click to view details of the report",
  },
];

//payments
export const paymentsTour = [
  {
    target: ".pstep1",
    content: "Transaction table",
  },
  {
    target: ".pstep2",
    content: "This is a search field",
  },
  {
    target: ".pstep3",
    content: "This is a filter option",
  },
  {
    target: ".pstep4",
    content: "Print & Export",
  },
  {
    target: ".pstep5",
    content: "Unpaid invoices",
  },
  {
    target: ".pstep6",
    content: "Payment settings",
  },
];

//settings
export const settingsTour = [
  {
    target: ".sstep1",
    content: "Switch between tabs",
  },
  {
    target: ".sstep2",
    content: "Edit profile details",
  },
  {
    target: ".sstep3",
    content: "Change profile picture",
  },
  {
    target: ".sstep4",
    content: "Change password",
  },
  {
    target: ".sstep5",
    content: "Change language",
  },
  {
    target: ".sstep6",
    content: "My Children",
  },
];
