import React from "react";
import { Typography } from "../../../components/typography/Typography";
import { Image } from "react-bootstrap";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Modal } from "antd";
import { useGeneralRequests } from "../../../context/generalContext";

interface dialogProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void
}

const SuccessDialog = ({ open, handleOpen, handleClose }: dialogProps) => {

  const { currWard } = useGeneralRequests()

  return (
    <>
      <Modal open={open} onCancel={handleOpen} centered footer={null} closeIcon={false} maskClosable={false} keyboard={false}>
        <div className="px-[20px] py-[30px]">
          <div className="flex justify-center items-center gap-2">
            <CheckCircleIcon className="w-10 h-10 text-defaultGreen" />
            <Typography.MediumText
              text="Ward Added Successfully"
              xStyle="text-defaultGreen font-semibold"
            />
          </div>

          <div className="h-[120px] overflow-y-auto">
            {/* user */}
            <div className="bg-defaultBlue_5 p-[20px] rounded-[20px] flex items-center gap-2 mt-[20px]">
              <Image
                className="object-cover h-[60px] w-[60px] rounded-full"
                src={currWard?.image || "https://api.dicebear.com/7.x/miniavs/svg?seed=3"}
                alt="user"
                fluid
              />
              <div className="w-full">
                <Typography.SmallText
                  text={currWard?.firstName + " " + currWard?.lastName}
                  xStyle="text-defaultBlue font-semibold"
                />
                <Typography.SmallText
                  text={"@" + currWard?.username}
                  xStyle="text-gray-500"
                />
              </div>
            </div>
          </div>

          <div className="mt-[25px] flex flex-col justify-center items-center px-[20px] lg:px-[40px]">
            <button className="border-[1px] border-defaultBlue text-defaultBlue font-semibold py-[10px] rounded-[50px] w-full" onClick={() => window.location.reload()}>
              <Typography.SmallText text="Add Another Child" />
            </button>
            <button className="mt-[10px] bg-defaultBlue text-white font-semibold py-[10px] rounded-[50px] w-full">
              <Typography.SmallText text="Proceed To Payment" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SuccessDialog;
