import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const MomoForm = () => {
  return (
    <div className="mt-[18px]">
      <Form as={Row}>
        <Form.Group as={Col} md={12} className="mb-3" controlId="formBasicText">
          <Form.Label className="text-defaultText opacity-60">
            Select Mobile Money Network *
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
          >
            <option value="1">MTN</option>
            <option value="2">Vodafone</option>
            <option value="3">Airtel / Tigo</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-3" controlId="formBasicText">
          <Form.Label className="text-defaultText opacity-60">
            Mobile Money Number *
          </Form.Label>
          <Form.Control
            className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
            type="text"
            placeholder="Mobile Money Number"
          />
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-3" controlId="formBasicText">
          <Form.Label className="text-defaultText opacity-60">
            Name registered to account *
          </Form.Label>
          <Form.Control
            className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
            type="text"
            placeholder="Account Holder Name"
          />
        </Form.Group>

        <p className="mt-[40px] text-defaultText text-[16px]">
          I agree to the{" "}
          <Link to="#" className="text-defaultBlue">
            Terms of Service
          </Link>{" "}
          and the{" "}
          <Link to="#" className="text-defaultBlue">
            Privacy Policy
          </Link>
        </p>

        <button
          type="submit"
          className="w-[146px] h-[40px] bg-defaultBlue text-defaultWhite rounded-[20px] flex ml-auto items-center justify-center"
        >
          Save Changes
        </button>
      </Form>
    </div>
  );
};

export default MomoForm;
