import { Typography } from "../../components/typography/Typography";
import pdfIcon from "../../images/pdfIcon.png";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const AllReports = () => {
  return (
    <div className="bg-defaultWhite rounded-[20px] h-full shadow-sm p-[30px]">
      <Typography.MediumText
        text="Nov. 2022 to Sept 2023"
        xStyle="font-semibold text-defaultText"
      />

      <div className="mt-[27px] mx-[20px] flex justify-between items-start">
        <Typography.MediumText text="Title" xStyle="text-defaultText" />
        <Typography.MediumText text="Term" xStyle="text-defaultText" />
        <Typography.MediumText
          text="Date Generated"
          xStyle="text-defaultText"
        />
      </div>

      <ReportSample
        title="Term report 2"
        term="Term 2"
        termDate="Nov. 2022 to Sept 2023"
        generatedDate="34/03/2022"
      />
    </div>
  );
};

interface reportProps {
  title: string;
  term: string;
  termDate: string;
  generatedDate: string;
}

//data mapper
const ReportSample = ({
  title,
  term,
  termDate,
  generatedDate,
}: reportProps) => {
  return (
    <div className="rstep1 outline outline-1 outline-defaultBlue_40 rounded-[20px] md:h-[57px] my-[10px]">
      <div className="ml-[20px] md:mr-[113px] mr-[10px] py-[10px] flex justify-between items-center">
        {/* first section */}
        <div className="flex gap-[10px] items-center">
          <Image src={pdfIcon} alt="..." fluid />
          <Link className="rstep2" to="/reportdetails">
            <Typography.MediumText text={title} xStyle="text-defaultBlue" />
          </Link>
        </div>
        {/* second section */}
        <div className="flex gap-[30px] items-center">
          <div className="border border-r border-defaultBlue h-[38px]"></div>
          <div>
            <Typography.MediumText text={term} />
            <Typography.SmallText text={termDate} xStyle="text-defaultBlue" />
          </div>
        </div>
        {/* third section */}
        <div className="flex gap-[30px] items-center">
          <div className="border border-r border-defaultBlue h-[38px]"></div>
          <div>
            <Typography.MediumText text={generatedDate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllReports;
