import { useState } from "react";
import { Typography } from "../../components/typography/Typography";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  CreditCardIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
import CreditCardForm from "./CreditCardForm";
import MomoForm from "./MomoForm";

const PaymentInfo = () => {
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Credit Card",
      icon: <CreditCardIcon className="w-5 h-5" />,
    },
    {
      name: "Mobile Money",
      icon: <DevicePhoneMobileIcon className="w-5 h-5" />,
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === tabActive) {
      return "w-auto h-[41px] flex items-center gap-[11px] px-[22px] bg-gradient-to-r from-gradient-blueStart to-gradient-blueEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-auto h-[41px] flex items-center gap-[11px] px-[22px] bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <>
      <div className="bg-defaultWhite md:h-[472px] w-full rounded-[20px] px-[30px] py-[25px] text-defaultText">
        <Typography.MediumText
          text="Select Method"
          xStyle="font-semibold mb-[20px]"
        />

        <div>
          <Tabs>
            <TabList className="flex gap-[12px] mb-[20px]">
              {tabNames.map((item, index) => (
                <Tab key={index} className="bg-transparent">
                  <button
                    onClick={setTabActive.bind(this, index)}
                    key={index}
                    className={showActive(index)}
                  >
                    {item.icon}
                    <Typography.MediumText text={item.name} />
                  </button>
                </Tab>
              ))}
            </TabList>
            <TabPanel>
              <CreditCardForm />
            </TabPanel>
            <TabPanel>
              <MomoForm />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;
