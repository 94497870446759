interface tagProps {
  tagName: string;
}

const PendingTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-orange bg-opacity-20 text-secondary-orange text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[90px] py-[4px]">
      {tagName}
    </p>
  );
};

const OnlineTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-violet bg-opacity-20 text-secondary-violet text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[90px] py-[4px]">
      {tagName}
    </p>
  );
};

const RunningTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-green bg-opacity-20 text-secondary-green text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[90px] py-[4px]">
      {tagName}
    </p>
  );
};

const DoneTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-tertiary-green bg-opacity-20 text-tertiary-green text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[90px] py-[4px]">
      {tagName}
    </p>
  );
};

const PresentTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-tertiary-green text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[25px] w-[70px] py-[1px]">
      {tagName}
    </p>
  );
};

const AbsentTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-tertiary-pink text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[25px] w-[70px] py-[1px]">
      {tagName}
    </p>
  );
};

const LateTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-orange text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[25px] w-[50px] py-[1px]">
      {tagName}
    </p>
  );
};

const ExcusedTag = ({ tagName }: tagProps) => {
  return (
    <p className="bg-defaultYellow text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[25px] w-[70px] py-[1px]">
      {tagName}
    </p>
  );
};

const Ages9To11 = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-orange text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[110px] py-[4px]">
      {tagName}
    </p>
  );
};

const Ages7To8 = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-violet text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[110px] py-[4px]">
      {tagName}
    </p>
  );
};

const Ages12To15 = ({ tagName }: tagProps) => {
  return (
    <p className="bg-tertiary-pink text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[110px] py-[4px]">
      {tagName}
    </p>
  );
};

const Ages4To6 = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-green text-defaultWhite text-center font-medium text-[16px] rounded-[20px] h-[30px] w-[110px] py-[4px]">
      {tagName}
    </p>
  );
};

const Paid = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-green bg-opacity-20 text-secondary-green text-center font-medium text-[14px] flex justify-center rounded-[20px] w-auto px-4">
      {tagName}
    </p>
  );
};

const Unpaid = ({ tagName }: tagProps) => {
  return (
    <p className="bg-secondary-pink bg-opacity-20 text-secondary-pink text-center font-medium text-[14px] flex justify-center rounded-[20px] w-auto px-4">
      {tagName}
    </p>
  );
};

export const StatusTags = {
  PendingTag,
  OnlineTag,
  DoneTag,
  RunningTag,
  PresentTag,
  AbsentTag,
  LateTag,
  ExcusedTag,
  Ages9To11,
  Ages7To8,
  Ages12To15,
  Ages4To6,
  Paid,
  Unpaid,
};
