import { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import {
  Bars3Icon,
  // CalendarDaysIcon,
  CheckBadgeIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  FolderIcon,
  HomeIcon,
  RocketLaunchIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import logoImg from "../../images/logo.png";
import LearnerCard from "./LearnerCard";
import HamburgerMenu from "../navbar/HamburgerMenu";
import { useGeneralRequests } from "../../context/generalContext";

interface sidebarProps {
  startedIcon?: string;
  startedActive?: string;
  dashIcon?: string;
  dashboardActive?: string;
  calenIcon?: string;
  calendarActive?: string;
  iconActive?: string;
  timetableActive?: string;
  coursesIcon?: string;
  coursesActive?: string;
  badgesIcon?: string;
  badgesActive?: string;
  reportsIcon?: string;
  reportsActive?: string;
  settingsIcon?: string;
  settingsActive?: string;
  paymentsIcon?: string;
  paymentsActive?: string;
}

const ProSideBar = ({
  startedIcon,
  startedActive,
  dashIcon,
  dashboardActive,
  calenIcon,
  calendarActive,
  iconActive,
  timetableActive,
  coursesIcon,
  coursesActive,
  badgesIcon,
  badgesActive,
  reportsIcon,
  reportsActive,
  settingsIcon,
  settingsActive,
  paymentsIcon,
  paymentsActive,
}: sidebarProps) => {

  const { allMyWards } = useGeneralRequests()
  const { collapseSidebar } = useProSidebar();
  const [sidebarVisibility, setSidebarVisibilty] = useState(
    localStorage.getItem("side_visibilty") === "true" || false
  );

  const toggleSideBar = () => {
    const newVisibilty = !sidebarVisibility;
    setSidebarVisibilty(newVisibilty);
    localStorage.setItem("side_visibilty", newVisibilty.toString());
  };

  useEffect(() => {
    const storedVisibilty = localStorage.getItem("side_visibilty");
    if (storedVisibilty) {
      setSidebarVisibilty(storedVisibilty === "true");
    }
  }, []);

  //toggle sidebar on mobile
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const handleToggle = () => {
    if (toggleSidebar) {
      setToggleSidebar(false);
    } else {
      setToggleSidebar(true);
    }
  };

  return (
    <>
      <div className="absolute top-5 z-50 left-3">
        <HamburgerMenu onClick={() => handleToggle()} />
      </div>
      <div
        className={`h-[100vh] overflow-y-auto overflow-x-hidden md:block ${toggleSidebar ? "block absolute z-50 bg-white" : "hidden"
          }`}
        id="mySidebar"
      >
        <Sidebar defaultCollapsed={sidebarVisibility}>
          <Menu className="relative pb-4">
            {/* logo */}
            <div className="flex gap-[24px] ml-6 mt-[15px] mb-[20px]">
              <Link
                className={sidebarVisibility ? "" : "hidden md:block"}
                to="#"
                onClick={() => {
                  collapseSidebar();
                  toggleSideBar();
                }}
              >
                <Bars3Icon className="w-[30px] h-[30px] text-defaultBlue" />
              </Link>
              <div>
                {sidebarVisibility ? (
                  ""
                ) : (
                  <Image src={logoImg} alt="logo" fluid />
                )}
              </div>
            </div>

            <div className="flex justify-end mx-3 absolute top-0 right-0 md:hidden">
              <XCircleIcon
                className="w-[30px] h-[30px] text-defaultBlue"
                onClick={() => setToggleSidebar(false)}
              />
            </div>

            {sidebarVisibility ? (
              ""
            ) : (
              <div className="mt-[25px] mb-[20px] ml-6">
                <LearnerCard />
              </div>
            )}

            {/* menus */}
            {
              allMyWards.length === 0 &&
              <MenuItem
                icon={
                  <RocketLaunchIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${startedIcon}`}
                  />
                }
                className={startedActive}
                component={<Link to="/" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Get Started
                </p>
              </MenuItem>
            }

            <div className="step2">
              <MenuItem
                icon={
                  <HomeIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${dashIcon}`}
                  />
                }
                className={dashboardActive}
                component={<Link to="/dashboard" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Dashboard
                </p>
              </MenuItem>

              {/* <MenuItem
                icon={
                  <CalendarDaysIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${calenIcon}`}
                  />
                }
                className={calendarActive}
                component={<Link to="/calendar" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Calendar
                </p>
              </MenuItem> */}

              {/* <MenuItem
                icon={
                  <CalendarDaysIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${iconActive}`}
                  />
                }
                className={timetableActive}
                component={<Link to="/dashboard" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Time table
                </p>
              </MenuItem> */}

              <MenuItem
                icon={
                  <FolderIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${coursesIcon}`}
                  />
                }
                className={coursesActive}
                component={<Link to="/courses" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Courses
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <CheckBadgeIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${badgesIcon}`}
                  />
                }
                className={badgesActive}
                component={<Link to="/badges" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Badges
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <UsersIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${reportsIcon}`}
                  />
                }
                className={reportsActive}
                component={<Link to="/reports" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Report
                </p>
              </MenuItem>

              <hr className="text-defaultBlue_40 mt-[20px] mx-[27px]" />

              <MenuItem
                icon={
                  <CreditCardIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${paymentsIcon}`}
                  />
                }
                className={`mt-[20px] ${paymentsActive}`}
                component={<Link to="/payments" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  My Payments
                </p>
              </MenuItem>

              <MenuItem
                icon={
                  <Cog6ToothIcon
                    className={`w-6 h-6 text-secondary_bg text-defaultBlue_60 ${settingsIcon}`}
                  />
                }
                className={settingsActive}
                component={<Link to="/settings" />}
              >
                <p className="font-normal text-defaultBlue_60 text-[16px] mt-1">
                  Settings
                </p>
              </MenuItem>
            </div>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
};

export default ProSideBar;
