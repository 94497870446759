import { Dropdown, Image } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import chartImg from "../../images/performanceChart.png";

const PerformanceCard = () => {
  return (
    <div className="h-[117px] rounded-[15px] px-[16px] py-[14px] bg-defaultBlue flex justify-between">
      <div>
        <Typography.SmallText
          text="Performance"
          xStyle="text-defaultWhite font-semibold"
        />

        <Typography.HeadingThree
          text="260"
          xStyle="text-defaultWhite mt-[10px]"
        />

        <Typography.ExtraSmallText
          text="Total Points Earned"
          xStyle="text-defaultWhite mt-[10px]"
        />
      </div>
      <div>
        <Dropdown>
          <Dropdown.Toggle className="text-[12px] border-none hover:bg-transparent focus:bg-transparent">
            This Week
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">This Week</Dropdown.Item>
              <Dropdown.Item href="#/action-2">This Month</Dropdown.Item>
              <Dropdown.Item href="#/action-3">This Year</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown.Toggle>
        </Dropdown>

        <Typography.SmallText
          text="23%"
          xStyle="text-defaultWhite mt-[2px] ml-[20px]"
        />

        <Image
          className="w-[72px] h-26px]"
          src={chartImg}
          alt="perfImg"
          fluid
        />
      </div>
    </div>
  );
};

export default PerformanceCard;
