import { Typography } from "../typography/Typography";
import welcomeImg from "../../images/welcomeImg.png";
import { Image } from "react-bootstrap";
import { Progress } from "antd";

interface currProps {
  courseTitle?: string;
  details1?: string;
  details2: string;
  image?: any;
  percent?: any;
}

const CurrentCourses = ({
  courseTitle,
  details1,
  details2,
  image,
  percent,
}: currProps) => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] py-[10px] pr-[20px] pl-[10px] h-[106px] bg-defaultWhite">
      <div className="flex gap-[12px]">
        {image}

        <div className="flex gap-[4px] justify-between w-full items-center">
          <div className="h-[79px] w-[137px]">
            <Typography.MediumText
              text={courseTitle}
              xStyle="font-semibold text-defaultBlue"
            />
            <Typography.MediumText text={details1} xStyle="mt-[10px]" />
            <Typography.MediumText text={details2} />
          </div>

          <div>
            <Progress type="circle" percent={percent} size={70} strokeColor="#FFC400" />
          </div>
        </div>
      </div>
    </div>
  );
};

interface sugProps {
  courseTitle?: string;
  details1?: string;
  image?: any;
}

const SuggestedCourses = ({ image, details1, courseTitle }: sugProps) => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] py-[10px] pr-[20px] pl-[10px] h-[106px] bg-defaultWhite">
      <div className="flex gap-[21px]">
        {image}

        <div className="h-[79px]">
          <Typography.MediumText
            text={courseTitle}
            xStyle="font-semibold text-defaultBlue"
          />
          <Typography.MediumText
            text={details1}
            xStyle="mt-[10px] line-clamp-2"
          />
        </div>
      </div>
    </div>
  );
};

const WelcomeCard = () => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] pl-[20px] pt-[20px] h-[117px] bg-defaultWhite">
      <div className="flex justify-between">
        <div>
          <Typography.HeadingFour
            text="Welcome 👋 Jonathan"
            xStyle="text-defaultBlue"
          />
          <Typography.MediumText
            text="Mawuli reached 80% of his goals this week!"
            xStyle="mt-[18px]"
          />
        </div>
        <div className="relative bottom-14 right-3">
          <Image
            src={welcomeImg}
            className="hover:translate-y-1"
            alt="..."
            fluid
          />
        </div>
      </div>
    </div>
  );
};

export const AllCards = {
  CurrentCourses,
  SuggestedCourses,
  WelcomeCard,
};
