import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import { Image } from "react-bootstrap";
import addLearnerImg from "../../images/addLearnerImg.png";
import tourImg from "../../images/tourImg.png";
import supportImg from "../../images/supportImg.png";
import { useNavigate } from "react-router-dom";
import { useGeneralRequests } from "../../context/generalContext";

const GetStarted = () => {

  const navigate = useNavigate();
  const { allMyWards, userData } = useGeneralRequests()


  return (
    <div className="flex overflow-hidden h-screen w-full">
      {/* Sidebar */}
      <ProSideBar startedActive="active" startedIcon="dashIconActive" />

      {/* Content */}
      <div className="lg:flex lg:flex-col lg:flex-1 w-full overflow-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="lg:flex lg:justify-center lg:items-center text-defaultText h-full px-4 lg:px-0 overflow-y-auto pb-20">
          <div className="2xl:w-[950px] xl:w-[850px] lg:w-[850px] md:w-[650px] w-full">
            <Typography.HeadingThree
              text={userData ? `Hi ${userData?.firstName}👋 Welcome to RoboCentre` : 'Hi there 👋, Welcome to RoboCentre'}
              xStyle="text-defaultBlue text-center"
            />
            {
              allMyWards.length === 0 ?
                <Typography.MediumText
                  text="Your account has no activity. This means you will not see any data on the dashboard unless you add a learner"
                  xStyle="text-center"
                />
                :
                <Typography.MediumText
                  text="Pick up right where you left off. Track the progress of your Ward(s) and stay up to date."
                  xStyle="text-center"
                />
            }

            <div className="mt-[30px] w-full lg:grid grid-cols-3 gap-[40px]">
              <div className="mt-[20px] w-full">
                <div
                  className="bg-white text-defaultText shadow-sm rounded-[20px] h-[265px] px-[30px] flex flex-col justify-center items-center cursor-pointer ease-in duration-200 hover:scale-105 border-[1px] border-transparent hover:border-defaultBlue"
                  onClick={() => navigate("/add_learner")}
                >
                  <Image src={addLearnerImg} alt="...add" fluid />

                  <Typography.SmallText
                    text="Add Learner"
                    xStyle="font-semibold mt-[42px] text-center"
                  />
                </div>
              </div>

              <div className="mt-[20px] w-full">
                <div
                  className="bg-white text-defaultText shadow-sm rounded-[20px] h-[265px] px-[30px] flex flex-col justify-center items-center cursor-pointer ease-in duration-200 hover:scale-105 border-[1px] border-transparent hover:border-defaultBlue"
                  onClick={() => navigate("/dashboard")}
                >
                  <Image src={tourImg} alt="...add" fluid />

                  <Typography.SmallText
                    text="Take a Quick Tour"
                    xStyle="font-semibold mt-[42px] text-center"
                  />
                </div>
              </div>

              <div className="mt-[20px] w-full">
                <div className="bg-white text-defaultText shadow-sm rounded-[20px] h-[265px] px-[30px] flex flex-col justify-center items-center cursor-pointer ease-in duration-200 hover:scale-105 border-[1px] border-transparent hover:border-defaultBlue">
                  <Image src={supportImg} alt="...add" fluid />

                  <Typography.SmallText
                    text="Contact Support"
                    xStyle="font-semibold mt-[42px] text-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
