import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CreditCardForm = () => {
  return (
    <div className="mt-[18px]">
      <Form as={Row}>
        <Form.Group as={Col} md={6} className="mb-3" controlId="formBasicText">
          <Form.Label className="text-defaultText opacity-60">
            Card Number *
          </Form.Label>
          <Form.Control
            className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
            type="text"
            placeholder="Card Number"
          />
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-3" controlId="formBasicText">
          <Form.Label className="text-defaultText opacity-60">
            Card Holder Name *
          </Form.Label>
          <Form.Control
            className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
            type="text"
            placeholder="Card Holder Name"
          />
        </Form.Group>

        <Form.Group
          as={Col}
          md={6}
          className="mb-3 mt-[22px]"
          controlId="formBasicText"
        >
          <Form.Label className="text-defaultText opacity-60">
            Expiry Date *
          </Form.Label>
          <Form.Control
            className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
            type="date"
            placeholder="Expiry Date"
          />
        </Form.Group>

        <Form.Group
          as={Col}
          md={6}
          className="mb-3 mt-[22px]"
          controlId="formBasicText"
        >
          <Form.Label className="text-defaultText opacity-60">
            CVV / CVC Code *
          </Form.Label>
          <Form.Control
            className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
            type="text"
            placeholder="CVV / CVC Code "
          />
        </Form.Group>

        <p className="mt-[40px] text-defaultText text-[16px]">
          I agree to the{" "}
          <Link to="#" className="text-defaultBlue">
            Terms of Service
          </Link>{" "}
          and the{" "}
          <Link to="#" className="text-defaultBlue">
            Privacy Policy
          </Link>
        </p>

        <button
          type="submit"
          className="w-[146px] h-[40px] bg-defaultBlue text-defaultWhite rounded-[20px] flex ml-auto items-center justify-center"
        >
          Save Changes
        </button>
      </Form>
    </div>
  );
};

export default CreditCardForm;
