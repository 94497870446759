import { useEffect, useState } from "react";
import { Input, message, Spin } from "antd";
import { Typography } from "../../../components/typography/Typography";
import { useGeneralRequests } from "../../../context/generalContext";
import { LoadingOutlined } from '@ant-design/icons';
import ShowAlert from "../../../components/alerts/all_alerts";
import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";


interface AccountDetailsProps {
  isComplete: boolean;
  onCompleteChange: (complete: boolean) => void;
}



const AccountDetails = ({ isComplete, onCompleteChange }: AccountDetailsProps) => {

  const { currWard, getMyWards } = useGeneralRequests()
  const [theUserName, setTheUserName] = useState("")
  const [thePassword, setThePassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    onCompleteChange(isComplete);
  }, [isComplete, onCompleteChange]);



  // handleUpdateInfo
  const handleUpdateInfo = async () => {
    if (currWard) {
      if (!theUserName) {
        message.warning("Enter a username for your ward")
      } else if (!thePassword) {
        message.warning("Enter a password for your ward")
      } else if (thePassword !== confirmPassword) {
        message.warning("Passwords do not match")
      } else {
        let update_data = {
          "_id": currWard?._id,
          "firstName": currWard?.firstName,
          "lastName": currWard?.lastName,
          "dob": currWard?.dob,
          "gender": currWard?.gender,
          "username": theUserName,
          "password": thePassword,
          "status": "DRAFTED"
        }

        console.log("---toUpdate:", update_data)

        ShowAlert.question_alert("Confirm Action", `This information will be saved for ${currWard?.firstName} ${currWard?.lastName}`, "Confirm")
          .then((results) => {
            if (results.isConfirmed) {
              setIsBusy(true)

              BaseService.put_api(global_variables.update_ward_info, update_data)
                .then((response) => {
                  console.log("updateRes:", response?.data)
                  message.success(`Account information updated for ${currWard?.firstName} ${currWard?.lastName}`)
                  getMyWards()
                  onCompleteChange(true)
                  setIsBusy(false)
                }).catch((error: any) => {
                  console.log("updateErr:", error)
                  message.error(error?.response?.data?.error || "An error occured. Please try again")
                  setIsBusy(false)
                })
            }
          })
      }
    } else {

    }
  }


  return (
    <div className="bg-white text-defaultText shadow-sm rounded-[20px] px-[30px] pt-[20px] pb-[30px]">
      <Typography.MediumText text="Learner Profile" xStyle="font-semibold" />
      <hr />

      {/* username & passwords */}
      <div className="mt-[20px]">
        <Input className="h-[44px] rounded-[5px] w-full" value={theUserName} onChange={(e) => setTheUserName(e.target.value)} placeholder="Username" disabled={isBusy} />
      </div>
      <div className="mt-[20px]">
        <Input className="h-[44px] rounded-[5px] w-full" value={thePassword} onChange={(e) => setThePassword(e.target.value)} placeholder="Password" disabled={isBusy} />
      </div>
      <div className="mt-[20px]">
        <Input className="h-[44px] rounded-[5px] w-full" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" disabled={isBusy} />
      </div>

      {/* next button */}
      <button className="bg-defaultBlue text-white font-semibold h-[50px] rounded-[30px] w-full mt-[40px] disabled:cursor-not-allowed" onClick={handleUpdateInfo} disabled={isBusy}>
        {
          isBusy ?
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
            :
            "Next"}
      </button>
    </div>
  );
};

export default AccountDetails;
