import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import { Link } from "react-router-dom";
import { Col, Image, Row } from "react-bootstrap";
import bigrobo_logo from "../../images/bigrobo_logo.png";
import school_logo from "../../images/school_logo.png";
import { ArrowDownTrayIcon, PrinterIcon } from "@heroicons/react/24/outline";

const ReportDetails = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar reportsActive="active" reportsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="flex gap-[18px] items-center">
            <Link to="/reports" className="text-defaultBlue text-[18px]">
              &#8592; End of Term 1 Report
            </Link>
          </div>

          <Row>
            <Col md={9} className="mt-[10px]">
              {/* cover page */}
              <div className="w-full h-[954px] bg-defaultWhite flex flex-col justify-between">
                <div className="grid grid-cols-3">
                  <div className="bg-defaultBlueHover h-[6px] col-span-1"></div>
                  <div className="bg-defaultYellow h-[6px] col-span-2"></div>
                </div>

                {/* title content */}
                <div className="flex flex-col justify-center items-center relative h-full">
                  <Image src={bigrobo_logo} alt="..." fluid />

                  <Typography.MediumText
                    text="Learn - Grow - innovate"
                    xStyle="font-semibold mt-[18px] text-defaultText"
                  />

                  <Image
                    className="mt-[55px]"
                    src={school_logo}
                    alt="..."
                    fluid
                  />

                  <Typography.RegularText
                    text="END OF TERM 2 REPORT"
                    xStyle="mt-[10px] text-defaultText"
                  />

                  <Typography.MediumText
                    text="RCCI Grade 5"
                    xStyle="font-semibold mt-[5px] text-defaultText"
                  />

                  <div className="absolute bottom-[15px]">
                    <Typography.MediumText
                      text="www.robocentregh.com"
                      xStyle="text-defaultText"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3">
                  <div className="bg-defaultBlueHover h-[20px] col-span-1"></div>
                  <div className="bg-defaultYellow h-[20px] col-span-2"></div>
                </div>
              </div>

              {/* second page */}
              <div className="w-full h-[954px] mt-[60px] bg-defaultWhite">
                <div className="grid grid-cols-3">
                  <div className="bg-defaultBlueHover h-[6px] col-span-1"></div>
                  <div className="bg-defaultYellow h-[6px] col-span-2"></div>
                </div>

                {/* content */}
                <Row className="p-[20px] relative w-full h-full">
                  {/* left */}
                  <Col md={3} className="mt-[22px]">
                    <Image
                      className="h-[113px] w-[113px] rounded-full object-cover flex mr-auto ml-auto"
                      src="https://kids.delaware.gov/wp-content/uploads/sites/192/2019/06/topic-kid.jpg"
                      alt="..."
                      fluid
                    />

                    <Typography.SmallText
                      text="Student Name"
                      xStyle="text-defaultText font-medium opacity-60 mt-[32px]"
                    />
                    <Typography.MediumText
                      text="Mawuli Togbor"
                      xStyle="text-defaultText font-medium"
                    />

                    <Typography.SmallText
                      text="Grade"
                      xStyle="text-defaultText font-medium opacity-60 mt-[10px]"
                    />
                    <Typography.MediumText
                      text="Grade 5"
                      xStyle="text-defaultText font-medium"
                    />

                    <Typography.SmallText
                      text="Instructor"
                      xStyle="text-defaultText font-medium opacity-60 mt-[10px]"
                    />
                    <Typography.MediumText
                      text="Eva Frompong"
                      xStyle="text-defaultText font-medium"
                    />
                    <Typography.MediumText
                      text="Lorem Ipsum"
                      xStyle="text-defaultText font-medium"
                    />
                  </Col>

                  {/* right */}
                  <Col md={9} className="mt-[6px]">
                    <div className="flex gap-[82px]">
                      <Image src={school_logo} alt="..." fluid />
                      <div>
                        <Typography.MediumText
                          text="Learning Skills International School"
                          xStyle="text-defaultText font-semibold"
                        />
                        <Typography.RegularText
                          text="END OF TERM REPORT"
                          xStyle="text-defaultText font-semibold"
                        />
                        <Typography.SmallText
                          text="Prepared on March 23, 2021"
                          xStyle="text-defaultText font-medium opacity-60"
                        />
                      </div>
                    </div>

                    <hr className="mt-[30px]" />

                    <div className="mt-[30px]">
                      <Typography.SmallText
                        text="Letter to Parents"
                        xStyle="text-defaultText"
                      />
                      <Typography.SmallText
                        text="The progress report is prepared for you to be able to follow your child’s development of conceptual understanding, skills, knowledge and learner proﬁle in a more detailed way.

                        You will ﬁnd your child’s progress in Primary Years Programme (PYP) Approaches To Learning  (ATL)  skills,  attitudes  and  behaviour.  The  teachers  provide  a  stimulating, challenging and engaging learning environment,  creating appropriate activities  for  the needs of all students’ learning styles.
                        
                        Furthermore,  we continue to  aim  at encouraging our students to experience the excitement of discovering that they are capable of achieving far  more  than  they  perceived  possible.  Hence,  we  have  high  expectations  of  our students, and they have high expectations of themselves."
                        xStyle="text-defaultText mt-[10px]"
                      />

                      <Typography.SmallText
                        text="Kind Regards,"
                        xStyle="text-defaultText mt-[10px]"
                      />
                    </div>

                    {/* signature */}
                    <div className="mt-[113px] flex gap-[156px] items-center">
                      <div className="w-[142px] border-t border-defaultBlue_40">
                        <Typography.SmallText
                          text="John Doe"
                          xStyle="text-defaultText font-medium mt-[7px]"
                        />
                        <Typography.SmallText
                          text="Head Master"
                          xStyle="text-defaultText font-medium opacity-60"
                        />
                      </div>
                      <div className="w-[142px] border-t border-defaultBlue_40">
                        <Typography.SmallText
                          text="John Doe"
                          xStyle="text-defaultText font-medium mt-[7px]"
                        />
                        <Typography.SmallText
                          text="Instructor"
                          xStyle="text-defaultText font-medium opacity-60"
                        />
                      </div>
                    </div>
                  </Col>

                  {/* footer */}
                  <div className="flex justify-between items-center absolute bottom-[30px]">
                    <div className="ml-[30px]">
                      <Typography.SmallText
                        text="Mawuli"
                        xStyle="text-defaultText font-medium opacity-60"
                      />
                      <div className="flex gap-[14px]">
                        <Typography.SmallText
                          text="END OF TERM 2 REPORT"
                          xStyle="text-defaultText font-medium"
                        />
                        <Typography.SmallText
                          text="Learning Skills International School"
                          xStyle="text-defaultText font-medium opacity-60"
                        />
                      </div>
                    </div>
                    <Typography.SmallText
                      text="Page 2/3"
                      xStyle="text-defaultText font-medium opacity-60 mr-[44px]"
                    />
                  </div>
                </Row>
              </div>

              {/* last page */}
              <div className="w-full h-[954px] mt-[60px] bg-defaultWhite">
                <div className="grid grid-cols-3">
                  <div className="bg-defaultBlueHover h-[6px] col-span-1"></div>
                  <div className="bg-defaultYellow h-[6px] col-span-2"></div>
                </div>

                {/* content */}
                <div className="w-full h-full relative py-[45px] px-[20px]">
                  {/* result template */}
                  <div>
                    <Typography.RegularText
                      text="Summary of Achievement"
                      xStyle="text-defaultText font-semibold"
                    />

                    <hr />
                    {/* headings */}
                    <div className="mt-[42px] flex justify-between items-center">
                      <Typography.MediumText
                        text="Introduction to Robotics"
                        xStyle="text-defaultText font-semibold  w-[298px]"
                      />
                      <Typography.MediumText
                        text="Achievment Level"
                        xStyle="text-defaultText font-semibold w-[130px]"
                      />
                      <Typography.MediumText
                        text="Grade"
                        xStyle="text-defaultText font-semibold"
                      />
                    </div>
                    <hr />
                    {/* results */}
                    <div className="mt-[10px] flex justify-between items-center">
                      {/* left */}
                      <Typography.SmallText
                        text="Course description   ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet"
                        xStyle="text-defaultText w-[298px]"
                      />
                      {/* middle */}
                      <div className="w-[130px]">
                        <div className="flex justify-between items-center">
                          <Typography.MediumText
                            text="A"
                            xStyle="text-defaultText"
                          />
                          <Typography.MediumText
                            text="B"
                            xStyle="text-defaultText"
                          />
                          <Typography.MediumText
                            text="C"
                            xStyle="text-defaultText"
                          />
                          <Typography.MediumText
                            text="D"
                            xStyle="text-defaultText"
                          />
                        </div>
                        <div className="flex justify-between items-center">
                          <Typography.MediumText
                            text="4"
                            xStyle="text-defaultText"
                          />
                          <Typography.MediumText
                            text="7"
                            xStyle="text-defaultText"
                          />
                          <Typography.MediumText
                            text="8"
                            xStyle="text-defaultText"
                          />
                          <Typography.MediumText
                            text="7"
                            xStyle="text-defaultText"
                          />
                        </div>
                      </div>
                      {/* right */}
                      <div>
                        <Typography.MediumText
                          text="A"
                          xStyle="text-defaultText"
                        />
                        <Typography.MediumText
                          text="B"
                          xStyle="text-defaultText"
                        />
                      </div>
                    </div>
                    <hr className="mt-[20px]" />

                    {/* Total */}
                    <div className="flex justify-between items-center">
                      <Typography.MediumText
                        text="Total"
                        xStyle="text-defaultText font-semibold w-[298px]"
                      />
                      <div className="flex justify-between items-center  w-[130px]">
                        <Typography.MediumText
                          text="4"
                          xStyle="text-defaultText"
                        />
                        <Typography.MediumText
                          text="7"
                          xStyle="text-defaultText"
                        />
                        <Typography.MediumText
                          text="8"
                          xStyle="text-defaultText"
                        />
                        <Typography.MediumText
                          text="7"
                          xStyle="text-defaultText"
                        />
                      </div>
                      <Typography.MediumText
                        text="8"
                        xStyle="text-defaultText font-semibold"
                      />
                    </div>
                    <hr className="mb-[8px]" />
                    {/* final grade */}
                    <div className="flex justify-between items-center">
                      <Typography.MediumText
                        text="Final Grade"
                        xStyle="text-defaultText font-semibold w-[298px]"
                      />
                      <Typography.RegularText
                        text="8"
                        xStyle="text-defaultText font-semibold"
                      />
                    </div>
                  </div>
                  {/* footer */}
                  <div className="flex justify-between items-center w-full absolute bottom-[30px]">
                    <div>
                      <Typography.SmallText
                        text="Mawuli"
                        xStyle="text-defaultText font-medium opacity-60"
                      />
                      <div className="flex gap-[14px]">
                        <Typography.SmallText
                          text="END OF TERM 2 REPORT"
                          xStyle="text-defaultText font-medium"
                        />
                        <Typography.SmallText
                          text="Learning Skills International School"
                          xStyle="text-defaultText font-medium opacity-60"
                        />
                      </div>
                    </div>
                    <div className="mr-[40px]">
                      <Typography.SmallText
                        text="Page 3/3"
                        xStyle="text-defaultText font-medium opacity-60"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3} className="mt-[10px]">
              {/* print button */}
              <button className="text-defaultBlue bg-defaultWhite rounded-[5px] outline outline-1 outline-defaultBlue w-[141px] h-[40px] flex justify-center items-center gap-[10px]">
                <PrinterIcon className="w-5 h-5" />
                <Typography.MediumText text="Print Report" />
              </button>
              <br />
              <button className="text-defaultBlue bg-defaultWhite rounded-[5px] outline outline-1 outline-defaultBlue w-[185px] h-[40px] flex justify-center items-center gap-[10px]">
                <ArrowDownTrayIcon className="w-5 h-5" />
                <Typography.MediumText text="Download Report" />
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
