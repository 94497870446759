import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'


interface theProps {
    onChange?: (data: any) => void
    disabled?: boolean
}


const GradeLevelSelector = ({ onChange, disabled }: theProps) => {

    const [isBusy, setIsBusy] = useState(true)
    const [allGradeLevels, setAllGradeLevels] = useState([])

    //onload
    useEffect(() => {

        const getAllGradeLevels = async () => {
            setIsBusy(true)

            try {
                const response = await BaseService.get_api(`${global_variables.grade_levels}`)
                console.log(response?.data)
                const rawData = response?.data?.payload
                const mappedData = rawData?.map((item: any) => (
                    { label: item?.name, value: item?._id }
                ))
                setAllGradeLevels(mappedData)
                setIsBusy(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllGradeLevels()

    }, [])



    return (
        <>
            <Select
                className="h-[44px] rounded-[5px] w-full" placeholder="Grade / Year"
                options={allGradeLevels}
                onChange={onChange}
                loading={isBusy}
                allowClear
                showSearch
                // onSearch={handleOnSearch}
                disabled={disabled || isBusy}
            />
        </>
    )
}

export default GradeLevelSelector
