import React, { useCallback, useEffect, useState } from "react";
import ProSideBar from "../../../components/sidebar/pro-sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Typography } from "../../../components/typography/Typography";
import { ArrowLeftIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import LearnerInfo from "./LearnerInfo";
import AccountDetails from "./AccountDetails";
import SchoolDetails from "./SchoolDetails";

const AddLearner = () => {
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(0);
  const [isLearnerInfoComplete, setIsLearnerInfoComplete] = useState(false);
  const [isAccountDetailsComplete, setIsAccountDetailsComplete] = useState(false);

  //list of steps
  const tabNames = ["Learner Info", "Account Details", "School Details"];

  // tabComponents
  const tabComponents = [
    <LearnerInfo isComplete={isLearnerInfoComplete} onCompleteChange={setIsLearnerInfoComplete} />,
    <AccountDetails isComplete={isAccountDetailsComplete} onCompleteChange={setIsAccountDetailsComplete} />,
    <SchoolDetails />
  ]

  const handleCompleteChange = useCallback((tabIndex: number, complete: boolean) => {
    if (complete) {
      setTabActive(tabIndex + 1);
    }
  }, []);

  useEffect(() => {
    handleCompleteChange(0, isLearnerInfoComplete);
  }, [isLearnerInfoComplete, handleCompleteChange]);

  useEffect(() => {
    handleCompleteChange(1, isAccountDetailsComplete);
  }, [isAccountDetailsComplete, handleCompleteChange]);


  return (
    <div className="lg:flex lg:flex-row overflow-hidden h-screen w-full">
      {/* Sidebar */}
      <ProSideBar startedActive="active" startedIcon="dashIconActive" />

      {/* Content */}
      <div className="lg:flex lg:flex-col lg:flex-1 w-full overflow-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-4 lg:px-0 h-full overflow-y-auto text-defaultText">
          <div className="flex gap-6 items-center">
            <button
              className="text-defaultBlue border-[1px] border-defaultBlue px-2 py-1 rounded-[30px] flex items-center"
              onClick={() => navigate("/")}
            >
              <ArrowLeftIcon className="h-4 w-5" />
              Back
            </button>
            <Typography.HeadingThree text="Learner's Details" />
          </div>

          {/* form section */}
          <div className="mt-[10px] 2xl:grid xl:grid lg:grid md:grid grid-cols-4 gap-[30px]">
            {/* left */}
            <div className="mt-[10px] w-full col-span-1">
              <div className="py-[30px] rounded-[20px] bg-white shadow-sm">

                {tabNames.map((tab, i) => (
                  <div
                    key={i}
                    className={
                      tabActive === i
                        ? "pl-[30px] py-[20px] bg-[#f8f8f8] cursor-pointer flex items-center gap-4"
                        : "pl-[30px] py-[20px] cursor-pointer flex items-center gap-4"
                    }
                    onClick={() => setTabActive(i)}
                  >
                    <CheckCircleIcon className="w-4 h-4 text-defaultGreen" />
                    <Typography.SmallText text={tab} />
                  </div>
                ))}
              </div>
            </div>

            {/* right */}
            <div className="mt-[10px] w-full col-span-2">
              {tabComponents[tabActive]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLearner;
