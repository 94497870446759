import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import profile_icon from "../../images/profile_icon.png";
import notify_icon from "../../images/notify_icon.png";
import payment_icon from "../../images/payment_icon.png";
import PersonalDetails from "./PersonalDetails";
import ProfilePicture from "./ProfilePicture";
import NameAndRole from "./NameAndRole";
import Children from "./Children";
import PaymentInfo from "./PaymentInfo";
import { settingsTour } from "../../utils/tourSteps";
import Joyride from "react-joyride";
import { isTourComplete } from "../../utils/isTourComplete";

const Settings = () => {
  //tour steps
  const [steps] = useState(settingsTour);

  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Profile",
      icon: <Image className="" src={profile_icon} alt="..." fluid />,
    },
    {
      name: "Notification",
      icon: <Image className="" src={notify_icon} alt="..." fluid />,
    },
    {
      name: "Payment",
      icon: <Image className="" src={payment_icon} alt="..." fluid />,
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "w-[254px] h-[60px] flex items-center gap-[17px] pl-[10px] bg-gradient-to-r from-gradient-lightGreenStart to-gradient-lightGreenEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 1 && i === tabActive) {
      return "w-[254px] h-[60px] flex items-center gap-[17px] pl-[10px] bg-gradient-to-r from-gradient-violetStart to-gradient-violetEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 2 && i === tabActive) {
      return "w-[254px] h-[60px] flex items-center gap-[17px] pl-[10px] bg-gradient-to-r from-gradient-deepPinkStart to-gradient-deepPinkEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-[254px] h-[60px] flex items-center gap-[17px] pl-[10px] bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {isTourComplete ? (
        <></>
      ) : (
        <Joyride
          steps={steps}
          run
          showSkipButton
          continuous
          showProgress
          styles={{
            options: {
              arrowColor: "rgba(0, 0, 0, 0.80)",
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              overlayColor: "rgba(0, 0, 0, 0.80)",
              primaryColor: "#6666EA",
              textColor: "#fff",
              zIndex: 1000,
            },
          }}
        />
      )}
      {/* Sidebar */}
      <ProSideBar settingsActive="active" settingsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Settings" />

          <Row className="mt-[10px]">
            {/* left column items */}
            <Col md={9} className="mt-[10px]">
              <Tabs>
                <TabList
                  className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto sstep1"
                  id="horizontalTab"
                >
                  {tabNames.map((item, index) => (
                    <Tab key={index} className="bg-transparent">
                      <button
                        onClick={setTabActive.bind(this, index)}
                        key={index}
                        className={showActive(index)}
                      >
                        {item.icon}
                        <Typography.MediumText text={item.name} />
                      </button>
                    </Tab>
                  ))}
                </TabList>

                <hr className="mb-[20px]" />

                <TabPanel>
                  <PersonalDetails />
                </TabPanel>
                <TabPanel>...</TabPanel>
                <TabPanel>
                  <Typography.RegularText
                    text="Payment"
                    xStyle="text-defaultText font-semibold mb-[10px]"
                  />
                  <PaymentInfo />
                </TabPanel>
              </Tabs>
            </Col>

            {/* right column items */}
            <Col className="mt-[10px]" md={3}>
              {/* profile pic section */}
              <ProfilePicture />

              {/* name section */}
              <NameAndRole />

              <hr className="my-[30px]" />

              <div className="sstep6">
                <Children />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Settings;
