import React, { useEffect, useState } from "react";
import { Typography } from "../../../components/typography/Typography";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import { DatePicker, Input, message, Select, Spin } from "antd";
import { IoImagesOutline } from "react-icons/io5";
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";
import { useGeneralRequests } from "../../../context/generalContext";


interface infoProps {
  isComplete: boolean
  onCompleteChange: (complete: boolean) => void;
}


const LearnerInfo = ({ isComplete, onCompleteChange }: infoProps) => {

  const { getMyWards } = useGeneralRequests()
  const [isBusy, setIsBusy] = useState(false)
  //info
  const [fName, setFName] = useState("")
  const [mName, setMName] = useState("")
  const [lName, setLName] = useState("")
  const [theDateofBirth, setTheDateofBirth] = useState<any>("")
  const [theGender, setTheGender] = useState("")
  //image
  const [imgUrl, setImgUrl] = useState<any>("")
  const [imgFile, setImgFile] = useState<any>(null)
  const [fileType, setFileType] = useState("")

  useEffect(() => {
    onCompleteChange(isComplete);
  }, [isComplete, onCompleteChange]);


  //handleOnboardLearner
  const handleOnboardLearner = async () => {
    if (!fName) {
      message.warning("Enter ward's frist name")
    } else if (!lName) {
      message.warning("Enter ward's last name")
    } else if (!theDateofBirth) {
      message.warning("Date of birth cannot be empty")
    } else if (!theGender) {
      message.warning("Please specify gender")
    } else if (!imgUrl) {
      message.warning("Please upload a photo of your ward")
    } else {

      let onboard_data: any = {
        "firstName": fName,
        "lastName": lName,
        "middleName": mName,
        "dob": theDateofBirth,
        "gender": theGender,
        "status": "DRAFTED"
      }
      console.log("---onboard_data:", onboard_data)

      if (!onboard_data?.middleName) {
        delete onboard_data?.middleName
      }

      setIsBusy(true)

      try {
        //upload image
        const imgRes: any = await BaseService.file_upload(fName, imgFile, fileType)
        console.log("imgUploadRes:", imgRes)

        const new_data = { ...onboard_data, "image": imgRes?.data?.url }

        //upload ward data
        try {
          const response = await BaseService.post_api(global_variables.add_new_ward, new_data)
          console.log("addWardRes:", response?.data)
          message.success("New ward was added successfully")
          getMyWards()
          setIsBusy(false)
          onCompleteChange(true)
        } catch (error: any) {
          console.log("addWardErr:", error)
          setIsBusy(false)
          message.error(error?.response?.data?.error || "An unexpected error occured. Try again")
        }
      } catch (error: any) {
        console.log("imgUploadErr:", error)
        setIsBusy(false)
        message.error(error?.response?.data?.error || "Error while upload image. Try again")
      }

    }
  }


  //handleDateChange
  const handleDateChange = (date: any) => {
    if (date) {
      setTheDateofBirth(date.format('YYYY-MM-DD'));
    } else {
      setTheDateofBirth("")
    }
  };

  //handleImageSelect
  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files?.[0];
    if (file) {
      setImgFile(file)
      const objUrl = URL.createObjectURL(file)
      setImgUrl(objUrl)
      setFileType(file?.type.split("/")[1])
    }
  }


  return (
    <div className="bg-white text-defaultText shadow-sm rounded-[20px] px-[30px] pt-[20px] pb-[30px]">
      <Typography.MediumText text="Learner Profile" xStyle="font-semibold" />
      <hr />

      {/* first & last names */}
      <div className="mt-[20px] w-full">
        <div className="lg:grid lg:grid-cols-3 gap-[20px]">
          <div className="mb-[10px]">
            <Input className="h-[44px] rounded-[5px] w-full" placeholder="First Name" value={fName} onChange={(e) => setFName(e.target.value)} disabled={isBusy} />
          </div>

          <div className="mb-[10px]">
            <Input className="h-[44px] rounded-[5px] w-full" placeholder="Middle Name" value={mName} onChange={(e) => setMName(e.target.value)} disabled={isBusy} />
          </div>

          <div className="mb-[10px]">
            <Input className="h-[44px] rounded-[5px] w-full" placeholder="Last Name" value={lName} onChange={(e) => setLName(e.target.value)} disabled={isBusy} />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-[20px] mt-[20px]">
          <div className="mb-[10px]">
            <DatePicker className="h-[44px] rounded-[5px] w-full" placeholder="Date of Birth" onChange={handleDateChange} disabled={isBusy} />
          </div>

          <div className="mb-[10px]">
            <Select
              className="h-[44px] rounded-[5px] w-full"
              options={[
                { label: "Male", value: 'M' },
                { label: "Female", value: 'F' },
                { label: "Rather not specify", value: 'O' }
              ]}
              placeholder="Gender"
              onChange={(selected) => setTheGender(selected)}
              disabled={isBusy}
              allowClear
            />
          </div>
        </div>
      </div>

      {/* upload photo */}
      <div className="mt-[20px] lg:flex lg:gap-[30px]">
        <div className="mt-[10px] flex justify-center">
          <label htmlFor="choose-img">
            <div className={`h-40 w-40 border-[2px] border-defaultBlue_60 border-dashed rounded-full flex flex-col justify-center items-center text-defaultBlue_60 ${isBusy ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
              {
                imgUrl ?
                  <img className="h-full w-full object-cover rounded-full" src={imgUrl} alt="" />
                  :
                  <>
                    <IoImagesOutline className="h-6 w-6" />
                    <p className="text-xs lg:text-[18px] mt-1">Choose image</p>
                  </>
              }
            </div>
          </label>
          <input className="hidden disabled:cursor-not-allowed" id="choose-img" onChange={handleImageSelect} type="file" disabled={isBusy} />
        </div>

        <div className="mt-[10px]">
          <Typography.SmallText
            text="Upload profile picture"
            xStyle="font-semibold"
          />
          <p className="font-medium text-[12px] text-unfilledText mt-[10px]">
            Photo will appear on the login and dashboard pages. The logo width
            should be less than{" "}
            <span className="text-defaultText font-semibold">400 pixels</span>.
          </p>
          <p className="font-medium text-[12px] text-unfilledText mt-[10px]">
            The recommended file formats are:
            <span className="text-defaultText font-semibold">
              jpg, jpeg, png
            </span>
          </p>

          <div className="">
            <button className="mt-[20px] text-tertiary-red border-[1px] border-tertiary-red  rounded-[5px] py-1 px-3 flex items-center gap-3 hover:bg-tertiary-red hover:text-white disabled:cursor-not-allowed" onClick={() => {
              setImgFile(null)
              setImgUrl("")
            }} disabled={isBusy}>
              <BackspaceIcon className="w-4 h-4" />
              Delete
            </button>
          </div>
        </div>
      </div>

      {/* next button */}
      <button className="bg-defaultBlue text-white font-semibold h-[50px] rounded-[30px] w-full mt-[40px] disabled:cursor-not-allowed" onClick={handleOnboardLearner} disabled={isBusy}>
        {
          isBusy ?
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
            :
            "Next"}
      </button>
    </div>
  );
};

export default LearnerInfo;
