import { Col, Image, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import { AllCards } from "../../components/cards/AllCards";
import { LargeButtons } from "../../components/buttons/LargeButtons";

const SuggestedCourses = () => {
  return (
    <>
      {/* suggested courses */}
      <Row className="mt-[30px]">
        <div className="py-[5px]">
          <Typography.HeadingFour
            text="Suggested Courses"
            xStyle="text-defaultText"
          />
        </div>

        <Col md={6} className="mt-[10px]">
          <AllCards.SuggestedCourses
            image={
              <Image
                src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                className="rounded-[12px] w-[96px] h-[84px] object-cover"
                fluid
              />
            }
            courseTitle="Practical Electronics"
            details1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                        molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                        numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                        optio, eaque rerum! Provident similique accusantium nemo autem."
          />
        </Col>

        <Col md={6} className="mt-[10px]">
          <AllCards.SuggestedCourses
            image={
              <Image
                src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                className="rounded-[12px] w-[96px] h-[84px] object-cover"
                fluid
              />
            }
            courseTitle="Practical Electronics"
            details1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                        molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                        numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                        optio, eaque rerum! Provident similique accusantium nemo autem."
          />
        </Col>

        <Col md={6} className="mt-[10px]">
          <AllCards.SuggestedCourses
            image={
              <Image
                src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                className="rounded-[12px] w-[96px] h-[84px] object-cover"
                fluid
              />
            }
            courseTitle="Practical Electronics"
            details1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                        molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                        numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                        optio, eaque rerum! Provident similique accusantium nemo autem."
          />
        </Col>

        <Col md={6} className="mt-[10px]">
          <AllCards.SuggestedCourses
            image={
              <Image
                src="https://img.i-scmp.com/cdn-cgi/image/fit=contain,width=1098,format=auto/sites/default/files/styles/1200x800/public/d8/images/canvas/2022/07/22/e4eed890-84f6-4d40-887e-345e96d59789_7b99bf05.jpg"
                className="rounded-[12px] w-[96px] h-[84px] object-cover"
                fluid
              />
            }
            courseTitle="Practical Electronics"
            details1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                        molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                        numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                        optio, eaque rerum! Provident similique accusantium nemo autem."
          />
        </Col>

        <div className="text-center mt-[34px]">
          <LargeButtons.Filled btnName="Explore All Topics" />
        </div>
      </Row>
    </>
  );
};

export default SuggestedCourses;
