import { useEffect, useState } from "react";
import { Typography } from "../../../components/typography/Typography";
import SuccessDialog from "./SuccessDialog";
import { message, Select, Skeleton, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";
import GradeLevelSelector from "../../../components/shared/gradeLevelSelector";
import SchoolSelectorComponent from "../../../components/shared/schoolSelectorComponent";
import { useGeneralRequests } from "../../../context/generalContext";


const SchoolDetails = () => {

  const [isBusy, setIsBusy] = useState(false)
  const { currWard } = useGeneralRequests()
  const [fetchingTypes, setFetchingTypes] = useState(true)
  const [allSchoolTypes, setAllSchoolTypes] = useState([])

  // sueccess dialog
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenSuccessDialog(true)
  };
  const handleCloseDialog = () => {
    setOpenSuccessDialog(false)
  };

  //input states
  const [eduType, setEduType] = useState<any>("")
  const [gradeLevel, setGradeLevel] = useState<any>("")
  const [theSchool, setTheSchool] = useState<any>("")
  const [theGuardian, setTheGuardian] = useState<any>("")


  //onload
  useEffect(() => {
    setFetchingTypes(true)

    const getSchoolTypes = async () => {
      try {
        const response = await BaseService.get_api(global_variables.school_types)
        // console.log(response?.data)
        setAllSchoolTypes(response?.data?.payload)
        setFetchingTypes(false)
      } catch (error) {
        console.log(error)
      }
    }

    getSchoolTypes()
  }, [])


  //handleSubmit
  const handleSubmit = async () => {
    if (!eduType) {
      message.warning("Please select the education type")
    } else if (!gradeLevel) {
      message.warning(`Please select grade level for ${currWard?.firstName}`)
    } else if (!theGuardian) {
      message.warning(`Please choose your relation to ${currWard?.firstName}`)
    }
    else {
      let enroll_data = {
        "wardId": currWard?._id,
        "educationalType": eduType,
        "gradeLevelId": gradeLevel,
        "schoolId": theSchool,
        "relation": theGuardian
      }

      if (!enroll_data?.schoolId) {
        delete enroll_data?.schoolId
      }

      console.log(enroll_data)
      setIsBusy(true)

      try {
        const response = await BaseService.post_api(global_variables.enroll_ward, enroll_data)
        console.log("---enrollRes:", response?.data)
        message.success(`School information for ${currWard?.firstName} has been saved`)
        setIsBusy(false)
        handleOpenDialog()
      } catch (error: any) {
        console.log("---enrollErr:", error)
        message.error(error?.response?.data?.error || "An unknown error occured. Please retry")
        setIsBusy(false)
      }
    }
  }


  return (
    <>
      <div className="bg-white text-defaultText shadow-sm rounded-[20px] px-[30px] pt-[20px] pb-[30px]">
        <Typography.MediumText text="School Info" xStyle="font-semibold" />
        <hr />

        {/* school info */}
        <div className="mt-[20px]">
          <Typography.SmallText text="Sign up as:" xStyle="font-semibold" />
        </div>

        {/* type */}
        <div className="w-full mt-[10px]">
          {
            fetchingTypes ?
              <>
                <Skeleton paragraph={{ rows: 2 }} active />
              </>
              :
              allSchoolTypes.map((item: any, i: number) => (
                <div key={i} className="form-check mt-[15px]">
                  <input
                    className="form-check-input cursor-pointer disabled:cursor-not-allowed"
                    type="radio"
                    name="flexRadioDefault"
                    id={item?.code}
                    onChange={() => setEduType(item?._id)}
                    disabled={isBusy}
                  />
                  <label className="form-check-label" htmlFor={item?.code}>
                    <Typography.MediumText text={item?.status} />
                    <Typography.SmallText
                      text={item?.description}
                      xStyle="text-unfilledText"
                    />
                  </label>
                </div>
              ))
          }
        </div>

        {/* grade */}
        <div className="mt-[20px]">
          <GradeLevelSelector onChange={(newVal) => setGradeLevel(newVal)} disabled={isBusy} />
        </div>

        {/* school */}
        <div className="mt-[20px]">
          <SchoolSelectorComponent onChange={(newVal) => setTheSchool(newVal)} disabled={isBusy} />
        </div>

        {/* relation */}
        <div className="mt-[20px]">
          <Select
            className="h-[44px] rounded-[5px] w-full"
            options={[
              { label: 'Father', value: 'FATHER' },
              { label: 'Mother', value: 'MOTHER' },
              { label: 'Guardian', value: 'GUARDIAN' }
            ]}
            onChange={(newVal) => setTheGuardian(newVal)}
            placeholder="Relation to ward"
            allowClear
            disabled={isBusy}
          />
        </div>

        {/* submit button */}
        <button
          onClick={handleSubmit}
          className="bg-defaultBlue text-white font-semibold h-[50px] rounded-[30px] w-full mt-[40px]"
        >
          {
            isBusy ?
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
              :
              "Submit"}
        </button>
      </div>

      {/* SuccessDialog */}
      {openSuccessDialog && (
        <SuccessDialog open={openSuccessDialog} handleOpen={handleOpenDialog} handleClose={handleCloseDialog} />
      )}
    </>
  );
};

export default SchoolDetails;
