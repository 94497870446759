import { Image } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import { Progress } from "antd";

const InProgressCourses = () => {
  return (
    <>
      <CourseSample percentage="80" />
      <CourseSample percentage="75" />
      <CourseSample percentage="92" />
    </>
  );
};

//data mapper
const CourseSample = ({ percentage }: any) => {
  return (
    <div className="bg-defaultWhite shadow-sm rounded-[20px] mb-[10px]">
      <div className="py-[10px] pr-[62px] pl-[12px] flex justify-between items-center">
        <div className="flex gap-[20px] w-[423px] justify-center items-center">
          <Image
            className="w-[96px] h-[84px] rounded-[12px] object-cover hidden md:block"
            src="https://previews.123rf.com/images/primagefactory/primagefactory1801/primagefactory180100176/92880816-beautiful-young-lady-individual-home-teacher-explaining-school-textbook-content-to-teach-her-student.jpg"
            alt="..."
            fluid
          />
          <div>
            <Typography.MediumText
              text="Coding and sobotics"
              xStyle="font-semibold text-defaultBlue"
            />
            <Typography.MediumText
              text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"
              xStyle="line-clamp-2 text-sm"
            />
          </div>
        </div>

        <div className="border-r-2 border-defaultBlue_20 h-[80px] ml-[100px] mr-[40px]"></div>

        <div className="w-[150px]">
          <Typography.MediumText text="Overall Progress" />
          <div className="my-[1px]">
            <Progress percent={percentage} showInfo={false} size="small" strokeColor="#FFC400" />
          </div>
          <Typography.MediumText text={`${percentage}%`} />
        </div>
      </div>
    </div>
  );
};

export default InProgressCourses;
