interface typoProps {
  text: any;
  xStyle?: any;
}

const HeadingOne = ({ text, xStyle }: typoProps) => {
  return <h1 className={`text-[56px] font-semibold ${xStyle}`}>{text}</h1>;
};

const HeadingTwo = ({ text, xStyle }: typoProps) => {
  return <h2 className={`text-[42px] font-semibold ${xStyle}`}>{text}</h2>;
};

const HeadingThree = ({ text, xStyle }: typoProps) => {
  return <h2 className={`lg:text-[28px] text-[16px] font-semibold ${xStyle}`}>{text}</h2>;
};

const HeadingFour = ({ text, xStyle }: typoProps) => {
  return <h2 className={`text-[21px] font-semibold ${xStyle}`}>{text}</h2>;
};

const RegularText = ({ text, xStyle }: typoProps) => {
  return <p className={`text-[21px] ${xStyle}`}>{text}</p>;
};

const MediumText = ({ text, xStyle }: typoProps) => {
  return <p className={`text-[16px] ${xStyle}`}>{text}</p>;
};

const SmallText = ({ text, xStyle }: typoProps) => {
  return <p className={`text-[13px] ${xStyle}`}>{text}</p>;
};

const ExtraSmallText = ({ text, xStyle }: typoProps) => {
  return <p className={`text-[10px] ${xStyle}`}>{text}</p>;
};

export const Typography = {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  RegularText,
  MediumText,
  SmallText,
  ExtraSmallText,
};
